// TODO prefix action_types to avoid collissions
export const ACTION_TYPES = {
  SET_INIT_STATUS: "ANALYTICS_SET_INIT_STATUS",
  UPDATE_COMMON: "ANALYTICS_UPDATE_COMMON",
  COOKIE_DECISION: "ANALYTICS_UPDATE_COOKIE_DECISION",
};

export const setInitStatus = ({allow, checked}) => {
  return {
    type: ACTION_TYPES.SET_INIT_STATUS,
    allow: allow,
    checked: checked
  };
};

export const updateCommon = (newParams) => {
  return {
    type: ACTION_TYPES.UPDATE_COMMON,
    newParams: newParams
  };
};

export const updateCookieDecision = ({accept, dismissed}) => {
  return {
    type: ACTION_TYPES.COOKIE_DECISION,
    accept: accept, 
    dismissed: dismissed
  };
};