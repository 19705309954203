import styled from "styled-components";
import { responsive } from "Styles/Constants";

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})`
  background-color: #fff;
  overflow-x: hidden;
  width: 100%;
`;

export const ContainerWrapper = styled.div.attrs({
  className: 'ContainerWrapper',
})`
  width: 1200px;

  ${responsive.LAPTOP`
        width : 950px;
    `}
  ${responsive.TABLET`
        width : ${props => props.newsBody ? "100%" : "580px"};
    `}
    ${responsive.PHABLET`
        width : ${props => props.newsBody ? "100%" : "420px"};
    `}
    ${responsive.MOBILE`
        width : ${props => props.newsBody ?? "100%"};
    `}
`;
