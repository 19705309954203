import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RaceWrapper } from './Home.style';

// IMAGES
import HUMAN from "Assets/images/Home/Races/Human.png";
import HALF_ORC from "Assets/images/Home/Races/HalfOrc.png";
import DWARF from "Assets/images/Home/Races/Dwarf.png";
import DROW from "Assets/images/Home/Races/Drow.png";
import HAlFING from "Assets/images/Home/Races/Halfling.png";
import TIEFLING from "Assets/images/Home/Races/Tiefling.png";
import HALFELF from "Assets/images/Home/Races/HalfElf.png";
import ASAIMAR from "Assets/images/Home/Races/Aasimar.png";
import GITH from "Assets/images/Home/Races/Gith.png";
import MOON_ELF from "Assets/images/Home/Races/MoonElf.png";
import DRAGON_BORN from "Assets/images/Home/Races/Dragonborn.png";
import SUN_ELF from "Assets/images/Home/Races/SunElf.png";
import WOOD_ELF from "Assets/images/Home/Races/WoodElf.png";

import { Button, Popover } from '@material-ui/core';
import { ContainerWrapper } from 'Components/Website.style';
import { useTranslation } from 'react-i18next';

function Races() {
    const language = useSelector(state => state.Auth.lang || "en");

    const [selectedRace, setRace] = useState(ROW1[0]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t : translate } = useTranslation();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const onSelectRace = (value) => {
        setRace(value);
        setAnchorEl(null);
    }

    return (
        <RaceWrapper language={language}>
            <div style={{marginBottom: '-22px', filter: 'brightness(1)'}} className="bg-image">
                <ContainerWrapper>
                    <div className="content">
                        <div className="container">
                            <div className="title">{translate('home.header.races')}</div>
                            <div className="border" />
                            <div className="dropdown">
                                <Button aria-describedby={id}
                                    endIcon={<div className="btn-icon" />}
                                    classes={{ endIcon: "end-icon" }}
                                    variant="outlined" className="races-dropdown-btn" color="primary" onClick={handleClick}>
                                    {translate(selectedRace.name)}
                                </Button>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    classes={{
                                        paper: "popover-root-races"
                                    }}
                                >
                                    <div className="dropdown-content">
                                        {ROW1.map((item, index) =>
                                            <React.Fragment key={item.name}>
                                                <div className="key" onClick={() => onSelectRace(item)}>{translate(item.name)}</div>
                                            </React.Fragment>
                                        )}
                                        {ROW2.map((item, index) =>
                                            <React.Fragment key={item.name}>
                                                <div className="key" onClick={() => onSelectRace(item)}>{translate(item.name)}</div>
                                                <div className="dummy-div" />
                                            </React.Fragment>
                                        )}
                                        {ROW3.map((item, index) =>
                                            <React.Fragment key={item.name}>
                                                <div className="key" onClick={() => onSelectRace(item)}>{translate(item.name)}</div>
                                                <div className="dummy-div" />
                                            </React.Fragment>
                                        )}
                                    </div>
                                </Popover>
                            </div>

                            <div className="container">
                                <div className="title">{translate(selectedRace.name)}</div>
                                <div className="desc">{translate(selectedRace.description)}</div>
                            </div>

                            <div className="tags">
                                {ROW1.map((item, index) =>
                                    <React.Fragment key={item.name}>
                                        <div className="key" onClick={() => onSelectRace(item)}>{translate(item.name)}</div>
                                        <div className="dummy-div" />
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="tags">
                                {ROW2.map((item, index) =>
                                    <React.Fragment key={item.name}>
                                        <div className="key" onClick={() => onSelectRace(item)}>{translate(item.name)}</div>
                                        <div className="dummy-div" />
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="tags">
                                {ROW3.map((item, index) =>
                                    <React.Fragment key={item.name}>
                                        <div className="key" onClick={() => onSelectRace(item)}><div className="zen-icon" />{translate(item.name)}</div>
                                        <div className="dummy-div" />
                                    </React.Fragment>
                                )}
                            </div>

                            <div className="border" />
                        </div>
                    </div>
                </ContainerWrapper>
            </div>

            <div className="information-container">
                <ContainerWrapper>
                    <div className="content">
                        <div className="container">
                            <div className="title">{translate(selectedRace.name)}</div>
                            <div className="desc">{translate(selectedRace.description)}</div>
                        </div>
                    </div>
                    <div className="image" style={{filter: 'brightness(1)', backgroundImage: `url(${selectedRace.image})` }} />
                    <div className="border" />
                </ContainerWrapper>
            </div>
        </RaceWrapper >
    )
}

export default Races;

const ROW1 = [
    {
        key: "Human",
        name: 'home.race-human.title',
        description: 'home.race-human.desc',
        image: HUMAN
    },

    {
        key: "HalfOrc",
        name: 'home.race-half-orc.title',
        description: 'home.race-half-orc.desc',
        image: HALF_ORC
    },
    {
        key: "Dwarf",
        name: 'home.race-dwarf.title',
        description: 'home.race-dwarf.desc',
        image: DWARF,
    },
    {
        key: "Drow",
        name: 'home.race-drow.title',
        description: 'home.race-drow.desc',
        image: DROW,
    }
]

const ROW2 = [
    {
        key: "WoodElf",
        name: 'home.race-wood-elf.title',
        description: 'home.race-wood-elf.desc',
        image: WOOD_ELF,
    },
    {
        key: "SunElf",
        name: 'home.race-sun-elf.title',
        description: 'home.race-sun-elf.desc',
        image: SUN_ELF,
    },
    {
        key: "Halfling",
        name: 'home.race-halfling.title',
        description: 'home.race-halfling.desc',
        image: HAlFING,
    },
    {
        key: "Tiefling",
        name: 'home.race-tiefling.title',
        description: 'home.race-tiefling.desc',
        image: TIEFLING,
    },
    {
        key: "HalfElf",
        name: 'home.race-half-elf.title',
        description: 'home.race-half-elf.desc',
        image: HALFELF,
    },
]

const ROW3 = [
    {
        key: "Aasimar",
        name: 'home.race-aasimar.title',
        description: 'home.race-aasimar.desc',
        image: ASAIMAR,
    },
    {
        key: "Gith",
        name: 'home.race-gith.title',
        description: 'home.race-gith.desc',
        image: GITH,
    },
    {
        key: "MoonElf",
        name: 'home.race-moon-elf.title',
        description: 'home.race-moon-elf.desc',
        image: MOON_ELF,
    },
    {
        key: "Dragonborn",
        name: 'home.race-dragon-born.title',
        description: 'home.race-dragon-born.desc',
        image: DRAGON_BORN,
    }
]

