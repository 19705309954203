export const ACTION_TYPES = {
  LOGIN_USER: "AUTH_LOGIN_USER",
  LOGOUT_USER: "AUTH_LOGOUT_USER",
  UPDATE_PROFILE: "AUTH_UPDATE_PROFILE",
  UPDATE_TOTAL_SCORE: "AUTH_UPDATE_TOTAL_SCORE",
  ADD_LANG: "AUTH_ADD_LANG",
  UPDATE_LANG: "AUTH_UPDATE_LANG",
  CODE_VERIFIER: "AUTH_CODE_VERIFIER",
  USER_IP: "AUTH_USER_IP",
  SET_IS_GDPR: "AUTH_SET_IS_GDPR",
  SET_IS_INTERNAL: "AUTH_SET_IS_INTERNAL",
  SET_REGION_OVERWRITE: "AUTH_SET_REGION_OVERWRITE",
}

export const loginUser = (data) => {
  // SET YOUR LOGIN INFO HERE
  return {
    type: ACTION_TYPES.LOGIN_USER,
    isLoggedIn: true,
    accessToken: data.accessToken
  };
};

export const codeVerifier = (data) => {
  return {
    type: ACTION_TYPES.CODE_VERIFIER,
    ...data,
  };
};

export const addLang = (data) => {
  return {
    type: ACTION_TYPES.ADD_LANG,
    ...data,
  };
};

export const updateLang = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_LANG,
    ...data,
  };
};

export const logOutUser = () => {
  return {
    type: ACTION_TYPES.LOGOUT_USER,
  };
};

/* PROFILE INFO */

export const updateProfileInfo = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_PROFILE,
    profileInfo: data,
  };
};

export const updateTotalScore = (score) => {
  return {
    type: ACTION_TYPES.UPDATE_TOTAL_SCORE,
    score,
  };
};

export const setUserIp = ({userIP}) => {
  return {
    type: ACTION_TYPES.USER_IP,
    userIP: userIP,
  };
};

export const setIsGdpr = ({isGdpr}) => {
  return {
    type: ACTION_TYPES.SET_IS_GDPR,
    isGdpr: isGdpr,
  };
};

export const setIsInternal = ({isInternal}) => {
  return {
    type: ACTION_TYPES.SET_IS_INTERNAL,
    isInternal: isInternal,
  };
};

export const setRegionOverwrite = ({regionOverwrite}) => {
  return {
    type: ACTION_TYPES.SET_REGION_OVERWRITE,
    regionOverwrite: regionOverwrite,
  };
};