import { FONTS, responsive } from "../../Styles/Constants";
import DownloadBGImage from "Assets/images/Download/bg.png";
import NeverWinter from "Assets/images/Download/logo.png";
import styled from "styled-components";

export const DownloadContainer = styled.div.attrs({
  className: 'DownloadContainer',
})`
  .bg-image {
    background: url(${DownloadBGImage});
    background-position: center;
    background-size: cover;
    height: 600px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .container {
      width: 1200px;
      height: 325px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;

      ${responsive.LAPTOP`
      width : 950px;
    `}
      ${responsive.TABLET`
      width : 600px;
    `}
    ${responsive.PHABLET`
      width : 420px;
      height : 325px;
    `}
    ${responsive.MOBILE`
      width : 300px;
    `}
    
    .danger {
      color: #f23030 ;
    }

    .image {
        background: url(${NeverWinter});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 55px
        z-index: 1;

      ${responsive.PHABLET`
        margin-left: 16px;
        width: 84%
      `}
      ${responsive.MOBILE`
        margin-left: 16px;
        width: 84%
      `}

      }

      .title {
        font-size: 32px;
        letter-spacing: 4.5px;
        text-transform: uppercase;
        font-family: ${FONTS.PRIMARY};
        padding: 35px 0;
        justify-content: center;
        display: flex;

        ${responsive.TABLET`
          font-size : 18px;
          padding : 35px 0 0;
        `}

        ${responsive.PHABLET`
          font-size : 16px;
          padding : 35px 25px;
        `}

        ${responsive.MOBILE`
          font-size : 16px;
          padding : 35px  25px;
        `}
      }

      .text {
        font-size: 14px;
        letter-spacing: 1px;
        padding: 0 8%;
        text-align: center;

        ${responsive.TABLET`
        padding : 20px 8% 30px;
        font-size : 14px;
      `}
      }
    }
    .border-box {
      width: 300px;
      height: 250px;

      ${responsive.TABLET`
        width : 250px;
        height : 200px;
      `}
      ${responsive.MOBILE`
        width : 200px;
      `}
    &.top {
        border-top: 3px solid #c0a873;
        border-right: 3px solid #c0a873;
        transform: translate(291%, 109%);

        ${responsive.LAPTOP`
        transform : translate(207%,109%);
      `}
        ${responsive.TABLET`
        transform : translate(130%,111%);
      `}
      ${responsive.PHABLET`
        transform : translate(60%,108%);
      `}
      ${responsive.MOBILE`
        transform : translate(45%,107%);
      `}
      }
      &.bottom {
        border-bottom: 3px solid #c0a873;
        border-left: 3px solid #c0a873;
        transform: translate(10%, -109%);

        ${responsive.LAPTOP`
        transform : translate(9%,-109%);
      `}
        ${responsive.TABLET`
        transform : translate(8%,-113%);
      `}
      ${responsive.PHABLET`
        transform : translate(6%,-108%);
      `}
      ${responsive.MOBILE`
        transform : translate(7%,-107%);
      `}
      }
    }
  }
`;
