import React from "react";
import { Button } from "@material-ui/core";
// import NewsHeader1 from "Assets/images/News/news_header1.png";
import { HeaderIcons } from "./News.style";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function HeaderContent(props) {
    const { item } = props
    const { t: translate } = useTranslation();
    // const [list, setList] = useState([])
    const language = useSelector(state => state.Auth.lang);
    const history = useHistory()

    const newsArticlePage = (id) => {
        history.push({ pathname: "/" + language + "/news-details/" + id })
    }

    const getFilters = (tags) => {
        // let filterData = {'nw-xbox': <div className="icon xbox-icon no-hover" />, "nw-playstation": <div className="icon ps-icon no-hover" />, "nw-launcher": <div className="icon window-icon no-hover" />, "nw-news": <div className="icon epic-icon no-hover" />}

        let filterData = {
            'nw-xbox': <div className="icon xbox-icon no-hover" />,
            "nw-playstation": <div className="icon ps-icon no-hover" />,
            "nw-news": <div className="icon window-icon no-hover" />,
        }

        let filterArray = []

        for (let i = 0; i < tags?.length; i++) {
            filterArray.push(filterData[tags[i]])
        }

        return filterArray
    }

    return (
        <>
            <div className="top-container flex f-v-center f-h-center">
                <svg className="image-container" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="676.201" height="392" viewBox="0 0 676.201 392">
                    <defs>
                        <pattern id="pattern" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" viewBox="0 0 800 303">
                        <image width="800" height="303" xlinkHref={item?.media?.length && item?.media[0].image?.url ? item.media[0].image.url : ''} alt={item?.media?.length && item.media[0].image?.label ? item.media[0].image.label : ''} />
                        </pattern>
                        <filter id="header_01" x="2.201" y="3" width="674" height="389" filterUnits="userSpaceOnUse">
                        <feOffset dx="5" dy="5" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="4" result="blur"/>
                        <feFlood floodOpacity="0.2"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                        </filter>
                        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#b7a379"/>
                        <stop offset="1" stopColor="#7e674a"/>
                        </linearGradient>
                        <linearGradient id="linear-gradient-2" y1="1" y2="0" xlinkHref="#linear-gradient"/>
                    </defs>
                    <g id="Group_238" data-name="Group 238" transform="translate(-351.234 -122.5)">
                        <g transform="matrix(1, 0, 0, 1, 351.23, 122.5)" filter="url(#header_01)">
                        <rect id="header_01-2" data-name="header_01" width="650" height="365" transform="translate(9.2 10)" fill="url(#pattern)"/>
                        </g>
                        <g id="Group_270" data-name="Group 270" transform="translate(-9 -10)">
                        <path id="Path_1299" data-name="Path 1299" d="M1025.317,955.648h335.038v212.336H1357.4V1012.1h-6.374l-1.474-1.474v-18.25h-5.5l-1.474-1.475V973.45h-17.249l-1.474-1.478v-6.248h-18.25l-1.474-1.475V958.6H1025.317Zm301.489,2.952v4.175h24.224l1.475,1.474v25.175h4.9V958.6Zm25.7,50.548h4.9V992.372h-4.9Zm-25.7-38.649h17.248l1.475,1.474v17.451h4.027v-23.7h-22.75Zm-19.725-7.724h16.775V958.6h-16.775Z" transform="translate(-331.604 -823.148)" fill="url(#linear-gradient)"/>
                        <path id="Path_1300" data-name="Path 1300" d="M1025.317,1167.984h335.038V955.648H1357.4v155.888h-6.374l-1.474,1.474v18.25h-5.5l-1.474,1.475v17.448h-17.249l-1.474,1.478v6.248h-18.25l-1.474,1.475v5.649H1025.317Zm301.489-2.952v-4.175h24.224l1.475-1.474v-25.175h4.9v30.824Zm25.7-50.548h4.9v16.775h-4.9Zm-25.7,38.649h17.248l1.475-1.474v-17.451h4.027v23.7h-22.75Zm-19.725,7.724h16.775v4.175h-16.775Z" transform="translate(-331.604 -651.484)" fill="url(#linear-gradient-2)"/>
                        <path id="Path_1302" data-name="Path 1302" d="M1360.328,1167.984H1026.317V955.648h2.952v155.888h6.374l1.474,1.474v18.25h5.5l1.474,1.475v17.448h17.249l1.474,1.478v6.248h18.251l1.474,1.475v5.649h277.788Zm-300.462-2.952v-4.175h-24.224l-1.475-1.474v-25.175h-4.9v30.824Zm-25.7-50.548h-4.9v16.775h4.9Zm25.7,38.649h-17.248l-1.475-1.474v-17.451h-4.027v23.7h22.75Zm19.725,7.724h-16.776v4.175h16.776Z" transform="translate(-666.083 -651.484)" fill="url(#linear-gradient-2)"/>
                        <path id="Path_1301" data-name="Path 1301" d="M1360.328,955.648H1026.317v212.336h2.952V1012.1h6.374l1.474-1.474v-18.25h5.5l1.474-1.475V973.45h17.249l1.474-1.478v-6.248h18.251l1.474-1.475V958.6h277.788ZM1059.866,958.6v4.175h-24.224l-1.475,1.474v25.175h-4.9V958.6Zm-25.7,50.548h-4.9V992.373h4.9Zm25.7-38.649h-17.248l-1.475,1.474v17.451h-4.027v-23.7h22.75Zm19.725-7.724h-16.776V958.6h16.776Z" transform="translate(-666.083 -823.148)" fill="url(#linear-gradient)"/>
                        </g>
                    </g>
                </svg>
                <div className="text">
                    <HeaderIcons className="flex">
                        <div className="icon-container flex">
                            {getFilters(item.tags)}
                        </div>
                    </HeaderIcons>
                    <div className="text-header"> {item.title} </div>
                    <div className="news-summary">
                        {item.summary}
                    </div>
                    <Button onClick={() => {
                        newsArticlePage(item.id)
                    }} className="read-more">{translate('news.read-more')}</Button>
                </div>
            </div>
        </>
    )
}
