import React from "react"
import { PageNotFoundContainer } from "./PageNotFound.style";
// import { ContainerWrapper } from "Components/Website.style";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'

const PageNotFound = () => {
    const history = useHistory();
    const { t: translate } = useTranslation();

    return (
        <PageNotFoundContainer>
            {/* <ContainerWrapper> */}
            <div className="container">
                <div className="page-404">
                    <div className="page-404-font"><b>4</b></div>
                    <div className="playButton" />
                    <div className="page-404-font"><b>4</b></div>
                </div>

                <div>
                    <h1>{translate("page.not.found.title")}</h1>
                </div>

                <div className="description">
                    <p className="description-font">{translate("page.not.found.description")}</p>
                </div>

                <div>
                    <Button onClick={() => history.replace("/")} className="return-homepage">{translate("return.to.homepage")}</Button>
                </div>
                <div className="map" />
            </div>
            {/* </ContainerWrapper> */}
        </PageNotFoundContainer>
    )
}

export default PageNotFound