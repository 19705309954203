import styled from 'styled-components';
import HeaderImage from "Assets/images/Forum/header-image.png";
import Logo from "Assets/images/Home/logo.png";
import ForwardIcon from "Assets/images/Forum/forward.png";
import { FONTS, responsive } from 'Styles/Constants';
import UserDefaultIcon from "Assets/images/Forum/user.png";

export const ForumWrapper = styled.div.attrs({
    className: 'ForumWrapper',
})`
    .bg-image {
        background-image: url(${HeaderImage});
        height: 30vh;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
            background-image: url(${Logo});
            height: 37px;
            width: 230px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }   
    }

    .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .notice {
            font-size: 16px;
            color : #8e8e8e;
            line-height: 24px;
            margin : 30px 0;

            .danger {
                color: #f23030;
                &:hover {
                    cursor: pointer;
                    color : red;
                }
            }
        }
        .top-section {
            display: flex;
            ${responsive.PHABLET`
                flex-wrap:wrap;
            `}
            .path {
                display: flex;

                .path-section {
                    margin : 0 5px 0 0;
                    display: flex;
                    align-items: center;

                    .path-name {
                        font-size: 14px;
                        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    }
                    .arrow-icon {
                        width: 6px;
                        height: 6px;
                        border-left: 1px solid #000;
                        border-bottom: 1px solid #000;
                        margin: 0 5px;
                        transform: rotate(-135deg);
                    }
                }
            }
            .search-section {
                margin : 0 0 0 auto;
                .search-input {
                    height: 40px;
                    width : 350px;
                    ${responsive.TABLET`
                        width : 300px;
                    `}
                    ${responsive.MOBILE`
                        margin : 10px 0 0;
                    `}
                    .notchedOutline-search-box {
                        border-color: #e6e6e6;
                    }
                    .search-icon {
                        height: 20px;
                        width: 20px;
                        margin : 0 10px 0 0;
                    }
                }
            }
        }
        .tab-section {
            height : 80px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: #fbfbfb;
            margin : 20px 0;
            padding : 0 20px 0 0;
            
            .title {
                font-size: 14px;
                color: #36373b;
                &:hover {
                    cursor: pointer;
                    color : #000;
                }
            }
            .divider {
                height: 16px;
                width: 1px;
                margin: 0 10px;
                background-color: #707070;
            }
        }

        .latest-developer-post {
            display: flex;
            margin : 30px 0 15px;
            .header {
                font-size : 18px;
                color : #36373b;
                font-family: ${FONTS.PRIMARY_BOLD};
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            .slider-icons {
                margin : 0 0 0 auto;
                display: flex;
                align-items: center;

                .arrow-icon {
                    background-image: url(${ForwardIcon});
                    background-size: contain;
                    background-position: center;
                    height: 8px;
                    width: 8px;
                    margin : 0 5px;
                    &:hover {
                        cursor: pointer;
                    }
                    &.reverse {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .slider {
            /* height : max-content; */
            background-color: #f6f6f6;
            margin : 0 0 20px 0;
            .slick-slide {
                /* max-width: 300px; */
            }

            .slider-card {
                height: max-content;
                padding : 0 0 0 20px !important;
                overflow : hidden;
                max-width: 300px;
                
                .title {
                    font-size: 14px;
                    color : #36373b;
                    font-family: ${FONTS.PRIMARY_BOLD};
                    margin : 30px 0 15px;
                }
                .container {
                    width : 100%;
                    height: 100%;
                    background-color: #f2f2f2;
                    display: flex;
                    margin : 0 0 20px 0;

                    .dummy-div {
                        width: 1%;
                        background-color: #dcdde1;
                    }
                    .content-slide {
                        margin : 0 0 0 10px;
                        width: calc(100% - 1%);
                        margin : 10px 0 0 10px;
                        
                        .scroll {
                            height: 80px;
                            width: 95%;
                            overflow-y: auto;
                            font-size: 12px;
                        }
                        .bottom-section {
                            height: 25px;
                            margin : 10px 0;
                            display: flex;
                            align-items: center;

                            .user-image {
                                background-image: url(${UserDefaultIcon});
                                height: 25px;
                                width: 25px;
                                background-position: center;
                                background-size: 100%;
                                background-repeat: no-repeat;
                            }

                            .user-name {
                                font-size: 12px;
                                color : #36373b;
                                margin : 0 0 0 15px;
                                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                            }
                            .date {
                                color : #8e8e8e;
                                font-size: 10px;
                                margin :  0 10px 0 auto;
                            }
                        }
                    }
                }
            }
        
        }
    }
`