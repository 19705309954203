import LazyLoader from '@loadable/component'

import { URL_HOME, URL_USER } from "Helpers/Paths";

export default [
    {
        path: "/",
        exact: true,
        component: LazyLoader(() => import('Components/Home/Home'))
    }, {
        path: URL_HOME,
        exact: true,
        component: LazyLoader(() => import('Components/Home/Home'))
    }, {
        path: URL_USER,
        exact: true,
        component: LazyLoader(() => import('Components/User/User'))
    }
]
