import React from "react";
import { RelatedNewsWrapper } from "./NewsDetails.style";
import FallbackImage from "Assets/images/News/news-fallback.webp";
import {useSelector} from "react-redux"
import { useHistory } from "react-router-dom";

export default function RelatedNews(props) {
    const { newsItem, getNewsArticleData } = props
    const language = useSelector(state => state.Auth.lang);
    const history = useHistory();

    const getArticle = () => {
        history.replace({ pathname: "/" + language + "/news-details/" + newsItem.id, state: { ...newsItem } });
        getNewsArticleData();
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <RelatedNewsWrapper onClick={getArticle} className="flex f-v-center" style={{cursor: "pointer"}}>
            <div className="related-news-image">
                <img src={newsItem?.images?.img_microsite_thumbnail?.url ?? FallbackImage} alt="" className="full-height full-width"/>
            </div>
            <div className="related-news-text-container flex f-h-center">
                <div className="related-news-title">
                    {newsItem?.title}
                </div>
                <p className="related-news-text">
                    {newsItem?.summary}
                </p>
            </div>
        </RelatedNewsWrapper>
    )
}