import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HASBRO from "Assets/images/Footer/hasbro.png";
import {
  Social,
  FooterWrapper,
  FooterLogos,
  FooterLegal,
  FooterLinks,
} from "@fe-packages/components-and-utilities";
import styled from "styled-components";
export const FullWidth = styled.div`
  width: 100%;
  text-align: center;
`;



const companyLogos = ['wizards', <img src={HASBRO} alt="hasbros" />, 'arcgames', 'cryptic', 'rating'];
const platformLogos = ['arc', 'epic', 'steam', 'ps4', 'xboxone'];

function Footer() {
  const language = useSelector(state => state.Auth.lang);
  const userIP = useSelector(state => state.Auth.userIP);
  const { t } = useTranslation();
  const {
    REACT_APP_API_URL,
    REACT_APP_ACCOUNT_URL,
    REACT_APP_CORP_URL
  } = process.env;
  const currentYear = new Date().getFullYear();

  /* eslint-disable */
  return <>
    <Social lang={language} apiBaseUrl={REACT_APP_API_URL} micrositeGame="nw" />
    <FooterWrapper>
      <FooterLogos lang={language} userip={userIP} apiBaseUrl={REACT_APP_API_URL} micrositeGame="nw" companyLogos={companyLogos} platformLogos={platformLogos} />
      <FooterLegal legalLines={[
        <>
          {t('footer.legals', { year: currentYear })}
          <Trans i18nKey="footer.legals.embracer">
            We are part of the <a href="https://embracer.com/" target="_blank" rel="noopener noreferrer">Embracer Group</a>.
          </Trans>
        </>
      ]} />
      <FooterLinks accountBaseUrl={REACT_APP_ACCOUNT_URL} corporateUrl={REACT_APP_CORP_URL} lang={language} supportUrl={"https://arcgames-neverwinter.zendesk.com/hc/"} additionalElements={
        <div className="footer-section__link-full-wrap">
          <a className="footer-section__link" href="#" onClick={() => { OneTrust.ToggleInfoDisplay(); }} >{t('Cookie Preferences')}</a>
					<span className="footer-section__link-separator"></span>
          <a className="footer-section__link" href="#" onClick={() => { OneTrust.ToggleInfoDisplay(); }} >{t('Do not sell or share my personal information')}</a>
        </div>}
      />
    </FooterWrapper>
  </>;
  /* eslint-enable */
}

export default Footer;
