import React from "react";
import { PacedContainer } from "./Home.style";
import PacedViedo from "../../../src/Assets/images/Video/FastPacedActionCombat_MP4.mp4";
import BackToTop from "Components/Commons/BackToTop/BackToTop";
import { ContainerWrapper } from "Components/Website.style";
import { useTranslation } from "react-i18next";
function Paced() {
  const { t } = useTranslation();
  return (
    <PacedContainer>
      <ContainerWrapper>
        <video autoPlay muted loop className="video-viewport">
          <source src={PacedViedo} type="video/mp4" />
        </video>
        <div className="bg-image">
          <div className="container">
            <div className="title">{t('home.header.paced')}</div>
            <div className="text">
              {
                t('home.action-combat')
              }
            </div>
          </div>
        </div>
        <BackToTop color="#FFF" />
      </ContainerWrapper>
    </PacedContainer>
  );
}

export default Paced;
