import React, { useState } from "react";
import { ClassesContainer } from "./Home.style";
import { Button, Popover } from "@material-ui/core";
import Class10 from "Assets/images/Class Icons/10.png";
import Class11 from "Assets/images/Class Icons/11.png";
import Class13 from "Assets/images/Class Icons/13.png";
import Class14 from "Assets/images/Class Icons/14.png";
import Class15 from "Assets/images/Class Icons/15.png";
import Class17 from "Assets/images/Class Icons/17.png";
import Class19 from "Assets/images/Class Icons/19.png";
import Class20 from "Assets/images/Class Icons/20.png";
import Class21 from "Assets/images/Class Icons/21.png";
import Class22 from "Assets/images/Class Icons/22.png";
import Class23 from "Assets/images/Class Icons/23.png";
import Class25 from "Assets/images/Class Icons/25.png";
import Class26 from "Assets/images/Class Icons/26.png";
import Class27 from "Assets/images/Class Icons/27.png";
import Class28 from "Assets/images/Class Icons/28.png";
import Class29 from "Assets/images/Class Icons/29.png";
import Class30 from "Assets/images/Class Icons/30.png";
import { ContainerWrapper } from "Components/Website.style";
import { useTranslation } from "react-i18next";


function Classes() {
  const [selectedClasses, setClasses] = useState(ROW1[0]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeClass, setActiveClass] = useState(classesList.BARBARIAN);
  const { t } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const onSelectClasses = (value) => {
    setActiveClass(value.key)
    setClasses(value);
    setAnchorEl(null);
  };
  const classClick = (item) => {
    if (activeClass && activeClass === item.key) setActiveClass(null)
    else setActiveClass(item.key)
    setClasses(item)
  };
  return (
    <ClassesContainer className="flex f-h-center">
      <ContainerWrapper>
        <section className="class-container">
          <div className="content">
            <div className="classes">{t('home.header.classes')}</div>

            <div className="dropdown">
              <Button
                aria-describedby={id}
                endIcon={<div className="btn-icon" />}
                classes={{ endIcon: "end-icon" }}
                variant="outlined"
                className="classes-dropdown-btn"
                color="primary"
                onClick={handleClick}
              >
                {t(selectedClasses.name)}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                classes={{
                  paper: "popover-root-races",
                }}
              >
                <div className="dropdown-content">
                  {ROW1.map((item, index) => (
                    <React.Fragment key={item.name}>
                      <div className="key" onClick={() => onSelectClasses(item)}>
                        {t(item.name)}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </Popover>
            </div>
            <div className="bbr">{t(selectedClasses.name)}</div>
            <div className="images">

              {ROW1.map(row =>
                <div className="image-section" key={row.name}>
                  <div className={`${row.styleClass} ${activeClass === row.key && "active"}`} onClick={() => classClick(row)} />
                  <div className="image-name">{t(row.name)}</div>
                </div>
              )}

            </div>
            {ROW1.map(row =>
              <div className={`${activeClass === row.key ? "active" : "inactive"}`} key={row.key}>
                <div className="display-image" style={{ backgroundImage: `url(${selectedClasses.image})` }} >
                  <span className="sentinel-details2">
                    <h2>{t(selectedClasses.title).toUpperCase()}</h2>
                    <div className="class-description">
                      {t(selectedClasses.description)}
                    </div>
                  </span>
                </div>
                <div className="display-image2" style={{ backgroundImage: `url(${selectedClasses.image1})` }} >
                  <span className="sentinel-details2">
                    <h2>{t(selectedClasses.heading).toUpperCase()}</h2>
                    <div className="class-description">
                      {t(selectedClasses.description1)}
                    </div>
                  </span>
                </div>
                <div className="sentinel">
                  <div className="sentinel-image" ><img src={selectedClasses.image} alt="" /></div>
                  <span className="sentinel-details">
                    <h2>{t(selectedClasses.title)}</h2>
                    <p>

                      {t(selectedClasses.description)}

                    </p>
                  </span>
                </div>
                <div className="blademaster">
                  <div className="bimg" ><img src={selectedClasses.image1} alt="" /></div>
                  <span className="sentinel-details">
                    <h2>{t(selectedClasses.heading)}</h2>
                    <p>
                      {t(selectedClasses.description1)}
                    </p>
                  </span>
                </div>
              </div>
            )}


          </div>


          <div className="container">
            <div className="border"></div>
            <div className="title">{t('home.header.forgotten-realms')}</div>

            <div className="descriptor">
              {
                t('home.forgotten-realms')
              }
            </div>

            <div className="travel-images">
              <div className="inner-images">
                <div className="barovia">
                  <span>{t('home.realms.barovia')}</span>
                </div>
                <div className="jungles">
                  <span>{t('home.realms.jungles')}</span>
                </div>
                <div className="sharandar">
                  <span>{t('home.realms.sharandar')}</span>
                </div>
                <div className="icesprire">
                  <span>{t('home.realms.icespire')}</span>
                </div>
                <div className="avernus">
                  <span>{t('home.realms.avernus')}</span>
                </div>
                <div className="underdark">
                  <span>{t('home.realms.underdark')}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContainerWrapper>

    </ClassesContainer>
  );
}

export default Classes;

const classesList = {
  BARBARIAN: "BARBARIAN",
  WIZARD: "WIZARD",
  WARLOCK: "WARLOCK",
  ROUGE: "ROUGE",
  BARD: "BARD",
  PALADIN: "PALADIN",
  FIGHTER: "FIGHTER",
  RANGER: "RANGER",
  CLERIC: "CLERIC"
}

const ROW1 = [
  {
    key: classesList.BARBARIAN,
    name: "classes.barbarian",
    title: "classes.barbarian.paragon1.title",
    image: Class10,
    image1: Class11,
    heading: "classes.barbarian.paragon2.title",
    description: "classes.barbarian.paragon1",
    description1: "classes.barbarian.paragon2",
    styleClass: "barbarian"
  },
  {
    key: classesList.WIZARD,
    name: "classes.wizard",
    title: "classes.wizard.paragon1.title",
    image: Class27,
    image1: Class14,
    heading: "classes.wizard.paragon2.title",
    description: "classes.wizard.paragon1",
    description1: "classes.wizard.paragon2",
    styleClass: "wizard"
  },
  {
    key: classesList.WARLOCK,
    name: "classes.warlock",
    title: "classes.warlock.paragon1.title",
    image: Class19,
    image1: Class26,
    heading: "classes.warlock.paragon2.title",
    description: "classes.warlock.paragon1",
    description1: "classes.warlock.paragon2",
    styleClass: "warlock"
  },
  {
    key: classesList.ROUGE,
    name: "classes.rogue",
    title: "classes.rogue.paragon1.title",
    image: Class30,
    image1: Class15,
    heading: "classes.rogue.paragon2.title",
    description: "classes.rogue.paragon1",
    description1: "classes.rogue.paragon2",
    styleClass: "rogue"
  },
  {
    key: classesList.BARD,
    name: "classes.bard",
    title: "classes.bard.paragon1.title",
    image: Class22,
    image1: Class25,
    heading: "classes.bard.paragon2.title",
    description: "classes.bard.paragon1",
    description1: "classes.bard.paragon2",
    styleClass: "bard"
  },
  {
    key: classesList.PALADIN,
    name: "classes.paladin",
    title: "classes.paladin.paragon1.title",
    image: Class23,
    image1: Class21,
    heading: "classes.paladin.paragon2.title",
    description: "classes.paladin.paragon1",
    description1: "classes.paladin.paragon2",
    styleClass: "paladin"
  },
  {
    key: classesList.FIGHTER,
    name: "classes.fighter",
    title: "classes.fighter.paragon1.title",
    image: Class28,
    image1: Class11,
    heading: "classes.fighter.paragon2.title",
    description: "classes.fighter.paragon1",
    description1: "classes.fighter.paragon2",
    styleClass: "fighter"
  },
  {
    key: classesList.RANGER,
    name: "classes.ranger",
    title: "classes.ranger.paragon1.title",
    image: Class29,
    image1: Class20,
    heading: "classes.ranger.paragon2.title",
    description: "classes.ranger.paragon1",
    description1: "classes.ranger.paragon2",
    styleClass: "ranger"
  },
  {
    key: classesList.CLERIC,
    name: "classes.cleric",
    title: "classes.cleric.paragon1.title",
    image: Class13,
    image1: Class17,
    heading: "classes.cleric.paragon2.title",
    description: "classes.cleric.paragon1",
    description1: "classes.cleric.paragon2",
    styleClass: "cleric"
  },
];
