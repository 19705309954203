import { css } from "styled-components";

// COLOR PALLET
export const COLORS = {
  // BLUE
  PRIMARY: "#2196f3",
  PRIMARY_DARK: "#1769aa",
  PRIMARY_LIGHT: "#4dabf5",

  // MARRON (BUTTON)
  SECONDARY: "#E32733",
  SECONDARY_DARK: "#ab003c",
  SECONDARY_LIGHT: "#f73378",

  ORANGE_DARK: "#f78035",
  ORANGE_LIGHT: "#FAA637",

  //CYAN
  TERTIARY: "#3A82A7",
  TERTIARY_LIGHT: "#92abbd",

  //RED
  DANGER: "#d32f2f",

  GREY_TEXT_COLOR: "#707070",

  // Golden
  GOLDEN_TITLE_COLOR: "#9E875E",
};

// BREAKPOINTS
export const BREAKPOINTS_VALUE = {
  DISPLAY: 1750,
  DESKTOP: 1450, // x_large
  LAPTOP: 1278, // large
  TABLET: 960, // medium
  PHABLET: 600, // small
  MOBILE: 450, // x_small
  TINY: 340,
};
export const BREAKPOINTS = {
  A_DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP}px`,
  A_LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP}px`,
  A_TABLET: `${BREAKPOINTS_VALUE.TABLET}px`,
  A_PHABLET: `${BREAKPOINTS_VALUE.PHABLET}px`,
  A_MOBILE: `${BREAKPOINTS_VALUE.MOBILE}px`,
  A_TINY: `${BREAKPOINTS_VALUE.TINY}px`,

  DISPLAY: `${BREAKPOINTS_VALUE.DISPLAY - 1}px`,
  DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP - 1}px`, // x_large
  LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP - 1}px`, // large
  TABLET: `${BREAKPOINTS_VALUE.TABLET - 1}px`, // medium
  PHABLET: `${BREAKPOINTS_VALUE.PHABLET - 1}px`, // small
  MOBILE: `${BREAKPOINTS_VALUE.MOBILE - 1}px`, // x_small
  TINY: `${BREAKPOINTS_VALUE.TINY - 1}px`
};

//Fonts

export const FONTS = {
  PRIMARY: "Crimson-Regular",
  PRIMARY_BOLD: "Crimson-Bold",
  PRIMARY_SEMI_BOLD: "Crimson-SemiBold",
  PRIMARY_MEDIUM: "Crimson-Medium",
  // PRIMARY_ITALIC  : "Gotham Book Italic",

  SECONDARY: "Roboto",
  SECONDARY_BOLD: "Roboto bold",
  SECONDARY_CONDENSED: "Roboto Condensed",
  GALDEANO: "Galdeano-regular",
  // SECONDARY_HEAVY   : "PolarisCondensed-Heavy"
};

export const responsive = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${BREAKPOINTS[label]}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
