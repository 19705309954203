import { Card, CardHeader, CardMedia, CardContent, Typography, Avatar } from "@material-ui/core";
import React from "react";
import { SocialContentWrapper } from "./News.style";
import { getTimeAgo } from "../../Helpers/Utils"
import { useSelector } from "react-redux"


export default function SocialContent(props) {
    const { item } = props
    const language = useSelector(state => state.Auth.lang);

    return (
        <SocialContentWrapper>
            <Card className={"social-content-card-conatiner"}>
                <CardHeader classes={{ title: "social-content-title", subheader: "social-content-subheader" }}
                    avatar={
                        <Avatar aria-label={item?.images?.user_image?.label} src={item?.images?.user_image?.url} />
                    }
                    title={item?.user_full_name}
                    subheader={`@${item?.user_screen_name ? item?.user_screen_name : ""} - ${item?.published ? getTimeAgo(item?.published, language) : ""}`}
                />

                <CardContent>
                    <Typography variant="body2" component="p" className="social-content-text">
                        {item?.text} <a href={item.url}>{item?.url}</a>
                    </Typography>
                </CardContent>
                {item?.images?.image_large?.url && <CardMedia
                    className={"social-content-image"}
                    image={item?.images?.image_large?.url}
                    title={item?.images?.image_large?.label}
                />}

            </Card>
        </SocialContentWrapper>
    )
}