import { ContainerWrapper } from 'Components/Website.style';
import React, { useRef } from 'react'
import { ForumWrapper } from "./Forum.style";
import ForumHeader from './ForumHeader';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import Slider from "react-slick";

function Forum() {
    let slider = useRef(null);
    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        vertical: false,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: BREAKPOINTS_VALUE.LAPTOP - 1,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: BREAKPOINTS_VALUE.TABLET - 1,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: BREAKPOINTS_VALUE.PHABLET - 1,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: BREAKPOINTS_VALUE.MOBILE - 1,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]

    };

    function slidePrev() {
        slider.current.slickPrev();
    }

    function slideNext() {
        slider.current.slickNext();
    }


    return (
        <ForumWrapper>
            <div className="bg-image" >
                <ContainerWrapper>
                    <div className="logo" />
                </ContainerWrapper>
            </div>
            <div className="content">
                <ForumHeader />
                <ContainerWrapper>
                    <div className="latest-developer-post">
                        <div className="header">{"Latest Developer posts"}</div>
                        <div className="slider-icons">
                            <div className="arrow-icon" onClick={slidePrev} />
                            <div className="arrow-icon reverse" onClick={slideNext} />
                        </div>
                    </div>

                    <div className="slider">
                        <Slider {...settings}
                            ref={slider}
                        >
                            {SLIDER_DATA.map((item, index) =>
                                <div className="slider-card">
                                    <div className="title elipsis">{item.title}</div>
                                    <div className="container">
                                        <div className="dummy-div" />
                                        <div className="content-slide">
                                            <div className="scroll" >
                                                {item.text}
                                            </div>
                                            <div className="bottom-section">

                                                <div className="user-image" />
                                                <div className="user-name">{item.user}</div>
                                                <div className="date">{item.date}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}
                        </Slider>
                    </div>
                </ContainerWrapper>
            </div>

        </ForumWrapper>
    )
}

export default Forum;


const SLIDER_DATA = [
    {
        title: "PATCH NOTES: NW.125.20210330B.19",
        text: "Patch Notes for tomorrow's (May 27) PC maintenance. Release Notes Events • Wonders of Gond: Players in a party should no longer have significantly lower drop rates than players going solo.",
        user: "nitrocris83",
        user_image: "",
        date: "May 27"
    },
    {
        title: "PATCH NOTES: NW.125.20210330B.19",
        text: "Patch Notes for tomorrow's (May 27) PC maintenance. Release Notes Events • Wonders of Gond: Players in a party should no longer have significantly lower drop rates than players going solo.",
        user: "nitrocris83",
        user_image: "",
        date: "May 27"
    },
    {
        title: "PATCH NOTES: NW.125.20210330B.19",
        text: "Patch Notes for tomorrow's (May 27) PC maintenance. Release Notes Events • Wonders of Gond: Players in a party should no longer have significantly lower drop rates than players going solo.",
        user: "nitrocris83",
        user_image: "",
        date: "May 27"
    },
    {
        title: "PATCH NOTES: NW.125.20210330B.19",
        text: "Patch Notes for tomorrow's (May 27) PC maintenance. Release Notes Events • Wonders of Gond: Players in a party should no longer have significantly lower drop rates than players going solo.",
        user: "nitrocris83",
        user_image: "",
        date: "May 27"
    },
    {
        title: "PATCH NOTES: NW.125.20210330B.19",
        text: "Patch Notes for tomorrow's (May 27) PC maintenance. Release Notes Events • Wonders of Gond: Players in a party should no longer have significantly lower drop rates than players going solo.",
        user: "nitrocris83",
        user_image: "",
        date: "May 27"
    }
]


