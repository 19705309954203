import styled from "styled-components";

export const BackToTopContainer = styled.div.attrs({
  className: 'BackToTopContainer',
})`
  .back-to-top {
    cursor: pointer;
    margin: 12px;
    text-align: center;
    z-index: 1000;
    transition: fill 0.2s;
    fill: ${props => props.color};
  
    &:hover {
      fill: #e32733;
    }
  }
`;
