import React, { } from "react";
import { Button } from "@material-ui/core";
import { HomeContainer } from "./Home.style";
import Description from "./Description";
import Story from "./Story";
import Races from "./Races";
import Paced from "./Paced"
import Classes from "./Classes"
import IntroVideo from "Assets/images/Home/NW_Intro.mp4";
import { useTranslation } from 'react-i18next'
import { BREAKPOINTS_VALUE } from "Styles/Constants";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";


function Home() {
  const { t: translate } = useTranslation();
  let history = useHistory();
  const language = useSelector(state => state.Auth.lang || "en");
  const { REACT_APP_ACCOUNT_URL } = process.env;

  const handleClick = () => {
    history.replace("/" + language + "/download")

    window.open(`${REACT_APP_ACCOUNT_URL}/download?lang=${language}&game=nw`, "_blank")
  }

  const handleSteamClick = () => {
    window.location.href = "https://store.steampowered.com/app/109600?utm_source=micro&utm_campaign=nw_brand_xx_xx_xx_xx_steambutton&utm_medium=nw_xx_brand_steam_xx_xx_xx_xx_xx_xx_xx_xx_xx_steambutton&utm_content=xx_nw_xx_xx_xx_xx_steam_xx_xx_xx&utm_term=ua";
  }

  return (
    <React.Fragment>
      <HomeContainer>
        <div className="bg-image">
          {window.innerWidth >= BREAKPOINTS_VALUE.PHABLET && <video width="100%" height="100%" autoPlay muted loop className="video-viewport">
            <source src={IntroVideo} type="video/mp4" />
          </video>}
          <div className="image" />
          <div className="text">{translate("home.header.description")}</div>
          <Button onClick={handleClick} className="play-now">{translate('home.play_now')}</Button>
          <Button onClick={handleSteamClick} className={`also-available-on-steam also-available-on-steam--${language}`}>{translate('home.also_available_on')}<span className="also-available-on-steam__steam-icon"/></Button>
        </div>
      </HomeContainer>
      <Description />
      <Story />
      <Races />
      <Classes />
      <Paced />
    </React.Fragment>
  )
}

export default Home;
