import { ACTION_TYPES } from "./Actions";

const initState = {
  initStatus: {  // only initialize if both are true
    allow: false,
    checked: false
  },
  commonAttributes: {},
  cookieDecision: {
    accept: false,
    dismissed: false
  }
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_INIT_STATUS:
      return {
        ...state,
        initStatus: {
          allow: action.allow,
          checked: action.checked
        },
      };
    case ACTION_TYPES.UPDATE_COMMON:
      // truncate values to 255 characters or something reasonable
      let n = {};
      if(Object.keys(action.newParams).length > 0) {
        n = Object.keys(action.newParams).reduce((pV, cV) => {
          pV[cV] = action.newParams[cV].substr(0, 255);
          return pV;
        }, {});
      }

      return {
        ...state,
        commonAttributes: {
          ...state.commonAttributes,
          ...n
        },
      };
    case ACTION_TYPES.COOKIE_DECISION:
      return {
        ...state,
        cookieDecision: {
          accept: action.accept,
          dismissed: action.dismissed,
        }
      }
    default:
      return state;
  }
};

export default Reducer;
