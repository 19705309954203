import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from './i18'
// THEMES
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  StylesProvider,
} from "@material-ui/core/styles";

// USER DEFINED
import Website from "./Components/Website";
import ScrollToTop from "./Components/ScrollToTop";
import { THEME_SETTINGS } from "./themeSettings";
import { UtilityStyles } from "./Styles/Utils";
import { store } from "./Redux/Store";
import { InitAnalytics } from "Helpers/Analytics";

const theme = createTheme(THEME_SETTINGS);

const App = () => {
  /* eslint-disable */

  window.setTimeout(() => {
    if(OneTrust?.InsertHTML) {
      OneTrust.InsertHTML(
        "<script></script>",
        'head',
        () => { InitAnalytics(); },
        null,
        "C0004"
      )
    }
  }, 2000);
/* eslint-enable */

  return (
    <Provider store={store}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
        <ScrollToTop />
        <Suspense fallback={null}>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <I18nextProvider i18n={i18n}>
                <Website />
              </I18nextProvider>
              <UtilityStyles />
            </MuiThemeProvider>
          </StylesProvider>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;