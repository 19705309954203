import styled from "styled-components";
import BackgroundImage from "Assets/images/PageNotFound/background.png"
import PlayButton from "Assets/images/PageNotFound/play_button.png"
import Map from "Assets/images/PageNotFound/map.png"
import { COLORS, FONTS, responsive } from "Styles/Constants";


export const PageNotFoundContainer = styled.div.attrs({
    className: 'PageNotFoundContainer',
})`
    background-image: url(${BackgroundImage});
    /* height: calc(100vh - 120px); */
    /* height: fit-content; */
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    

    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 15px;
    }

    .page-404{
        display: flex; 
        flex-direction: row; 
        align-items: baseline;
    }

    .page-404-font{
        font-size: 100px;
    }

    .description{
        width: 450px; 
        text-align: center;

        ${responsive.MOBILE`
            width: 378px;
        `}
    }

    .description-font{
        font-size: 26px;
    }

    .playButton{
        background-image: url(${PlayButton});
        display: flex;
        height: 100px;
        width: 118px;
        margin: 0 10px;
    }

    .map{
        background-image: url(${Map});
        height: 900px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: -200px;

        ${responsive.MOBILE`
            display: none;
        `}
    }

    .return-homepage{
        margin: 35px 0;
        color: #fff;
        font-size: 18px;
        font-family: ${FONTS.GALDEANO};
        background-color: ${COLORS.SECONDARY};
        width: 300px;
        height: 50px;
        z-index: 1;
        border-radius: 2px;
        &:hover {
            background-image: linear-gradient(to bottom, #e32733 10%, #69191e);
        }
        ${responsive.TABLET`
            margin: 16px;
            width : 200px;
            font-size : 16px;
        `}
    }
`