import React from 'react'
import { useTranslation } from "react-i18next";
import { DownloadContainer } from "./Download.style";
import { ContainerWrapper } from "Components/Website.style";
import { Link } from '@material-ui/core';
import { useSelector } from "react-redux"

function Download() {
    const { t: translate } = useTranslation();
    const { REACT_APP_ACCOUNT_URL } = process.env;
    const language = useSelector(state => state.Auth.lang || "en");


    const playNow = () => {
        window.open(`${REACT_APP_ACCOUNT_URL}/download?lang=${language}&game=nw`, "_blank")
    }

    return (
        <DownloadContainer>
            <div className="bg-image">
                <ContainerWrapper>
                    <div className="border-box top" />
                    <div className="container">
                        <div className="image" />
                        <div className="title">{translate("download.title")}</div>
                        <div className="text">{translate("download.description")}  <Link onClick={playNow} className="danger" href="#">{translate("download.click_here")}</Link></div>
                    </div>
                    <div className="border-box bottom" />
                </ContainerWrapper>
            </div>
        </DownloadContainer>
    )
}

export default Download