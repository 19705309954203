import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { loadState, saveState } from './localStorage';
import { ACTION_TYPES as AUTH_ACTION_TYPES  } from "./Auth/Actions";

import reducers from "./Reducers";

const env = process.env.REACT_APP_ENV;
const middlewares = [];
const composeEnhancers =
  env !== "prod" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify here name, actionsBlacklist, actionsCreators and other options
      })
    : compose;

const initialState = loadState() !== undefined ? loadState() : {};


// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
// TLDR:
// Wrap combineReducers in to an appReducer
// If the action is LOGOUT_USER
//   don't use the previous state, force clear it.
const appReducer = combineReducers({
  ...reducers,
});

const rootReducer = (state, action) =>  {
  if(action.type === AUTH_ACTION_TYPES.LOGOUT_USER) {
    localStorage.setItem('stateVersion', -1); // set the state version to -1 so it's marked as dirty and won't be reused. See localStorage.js
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
}

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
);

store.subscribe(() => {
  const s = store.getState();
  saveState({
    Auth: {
      ...s.Auth,
    },
    Analytics: {
      ...s.Analytics
    },
    // General - nothing to persist from here yet
  });
})

export { store };
