
import React, { useEffect, useRef, useState }  from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { generateCodeVerifier, generateCodeChallenge } from 'Helpers/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { codeVerifier, logOutUser, loginUser, updateProfileInfo } from "Redux/Auth/Actions";
import querystring from 'querystring';
import { useTranslation } from 'react-i18next';
import Api from 'Helpers/ApiHandler';
import HeaderLogo from 'Assets/images/header_logo.webp';

import {
    HeaderWrapper,
    GameDropdown,
    Branding,
    HamburgerWrapper,
    UserComponents,
    CtaComponents,
    LinkComponents
} from "@fe-packages/components-and-utilities";

function Topbar() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [cartCount, setCartCount] = useState(0);
    const containerRef = useRef(document.getElementById('everything-container'));
    const {
        REACT_APP_OAUTH_URL,
        REACT_APP_API_URL,
        REACT_APP_ACCOUNT_URL,
        REACT_APP_SITE_GAME_ABBR,
        REACT_APP_SITE_URL,
        REACT_APP_CLIENT_ID,
        REACT_APP_DOWNLOAD_URL,
        REACT_APP_BILLING_URL,
      } = process.env;

    const code_verifier = useSelector(state => state.Auth.codeVerifier);
    const isLoggedIn = useSelector(state => state.Auth.isLoggedIn);
    const language = useSelector(state => state.Auth.lang);
    const userInfo = useSelector(state => state.Auth.profileInfo);

    useEffect(() => {
        let urlProps = new URLSearchParams(location?.search)

        if (urlProps.get('code')) {
            let auth_token = urlProps.get('code');

            let requestData = {
                grant_type: "authorization_code",
                client_id: REACT_APP_CLIENT_ID,
                code_verifier: code_verifier || sessionStorage.getItem("NW::code_verifier"),
                code: auth_token,
                redirect_uri: `${window.location.origin}${window.location.pathname}`,
            };
            sessionStorage.removeItem("NW::code_verifier");
            getToken(requestData);
        }
        getCartCount();
        // eslint-disable-next-line
    }, [language]);

    const getCartCount = async () => {
        let response = await new Api().get("games/cart");
        setCartCount(response?.cart_count || 0)
    }

    const getToken = async (requestData) => {

        fetch(`${REACT_APP_OAUTH_URL}/token`, {
            method: "POST",
            headers: {
                "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: querystring.stringify(requestData),
        }).then(response => {
            return response.json()
        }).then(data => {
            dispatch(loginUser({ accessToken: data.access_token }))
            getUserDetails();
        }).catch((e) => {
            console.log("error in token", e);
        })
    }

    const getUserDetails = async () => {
        let response = await new Api().get("users/me");
        dispatch(updateProfileInfo(response));
        
        let redirect = sessionStorage.getItem("NW::login_redirect");
        sessionStorage.removeItem("NW::login_redirect");
        history.replace(redirect !== null ? redirect : `${window.location.pathname}`);
    }

    const playNowHandler = () => {
        window.open(`${REACT_APP_ACCOUNT_URL}/download?lang=${language}&game=pwi`);
        history.push(`/${language}/success`)
    }

    const signIn = (redirect) => {
        sessionStorage.setItem("NW::login_redirect", typeof redirect === "string" ? redirect : window.location.pathname);
        const code = generateCodeVerifier(128);
        sessionStorage.setItem("NW::code_verifier", code);
        dispatch(codeVerifier({ codeVerifier: code }));
        const codeChallenge = generateCodeChallenge(code);
        window.open(`${REACT_APP_OAUTH_URL}/authorize?theme=nw&game_abbr=nw&referred_url=${REACT_APP_SITE_URL}/&state=629dd585fafa8375498851a0c67a8a6e&scope=id%20email&response_type=code&approval_prompt=auto&redirect_uri=${`${REACT_APP_SITE_URL}/${language}/`}&client_id=${REACT_APP_CLIENT_ID}&lang=${language}&code_challenge=${codeChallenge}&code_challenge_method=S256`, '_self')
    }

    const logOut = async () => {
        dispatch(logOutUser());
        history.replace(window.location.pathname);
    }

    return 	<HeaderWrapper lang={language} apiBaseUrl={REACT_APP_API_URL} arcGamesBaseUrl={REACT_APP_ACCOUNT_URL} containerRef={containerRef}>
		<GameDropdown micrositeGame={REACT_APP_SITE_GAME_ABBR} />
		<Branding gameName="Neverwinter" brand={<img src={HeaderLogo} width="24" height="23" alt="Neverwinter" />}/>
		<HamburgerWrapper>
			<LinkComponents.LinksWrapper>
                <LinkComponents.Link href={`${REACT_APP_DOWNLOAD_URL}/${language}/storefront/neverwinter`} target="_blank" rel="noreferrer noopenner">{t('navbar.store')}</LinkComponents.Link>
                <LinkComponents.MultiLink
                    keyIdentfier="community-links"
                    links={[
                        { item: <LinkComponents.MultiLinkItem href={`${REACT_APP_ACCOUNT_URL}/${language}/nw-refer-a-friend`} target="_blank">{t('navbar.refer-friend')}</LinkComponents.MultiLinkItem> },
                        { item: <LinkComponents.MultiLinkItem href={`${REACT_APP_ACCOUNT_URL}/${language}/forums/neverwinter`}>{t("navbar.forum")}</LinkComponents.MultiLinkItem> }
                    ]}
                >
                    {t("navbar.community")}
                </LinkComponents.MultiLink>
				<LinkComponents.Link href={`/${language}/news`} onClick={(e) => { history.push(`/${language}/news`); e.preventDefault()}}>{t("navbar.news")}</LinkComponents.Link>
				<LinkComponents.Link href={`https://support.playneverwinter.com/hc/${language === "en" ? "en-us" : language}/`} target="_blank" rel="noreferrer noopenner">{t("navbar.support")}</LinkComponents.Link>
			</LinkComponents.LinksWrapper>
			<UserComponents.UserWrapper paymentBaseUrl={REACT_APP_BILLING_URL}>
                <UserComponents.User
                    userNickname={isLoggedIn ? userInfo.nickname : ""}
                    signInAction={{type: "function", action: signIn}}
                    signOutAction={{type: "function", action: logOut}}
                />
                <UserComponents.Cart cart_count={cartCount} />
				<UserComponents.LangSelector langList={["en", "de", "fr", "it", "ru"]} />
			</UserComponents.UserWrapper>
			<CtaComponents.CtaWrapper 
				primary={<CtaComponents.PrimaryButton
					as="button"
					onClick={playNowHandler}
				>{t("button.play")}</CtaComponents.PrimaryButton>}
			/>
		</HamburgerWrapper>
	</HeaderWrapper>;
}

export default Topbar;
