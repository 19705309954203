import { store } from "../Redux/Store";
import { setInitStatus as updateCommon } from "../Redux/Analytics/Actions";


let debounceLoadingAnalytics = false; 
const InitAnalytics = () => {

  if(debounceLoadingAnalytics) {
    return false;
  }

  debounceLoadingAnalytics = true;

  const interestingParams = [
    "hoff_transaction_id",
    "hoff_vendor_id",
    "hoff_offer_id",
    "hoff_campaign_id",
    "hoff_aff_sub1",
    "hoff_aff_sub2",
    "hoff_aff_sub3",
    "hoff_aff_sub4",
    "hoff_aff_sub5",
    "url_id",
    "gclid",
    "pwe_seg",
    "utm_source",
    "utm_medium",
    "utm_term",
    "utm_campaign",
    "utm_content",
  ];

  // this is where we'd add in the funnel_id if we want to use that, too

  const params = new URLSearchParams(window.location.search);
  const used = interestingParams.reduce((previousValue, currentValue, currentIndex) => {
    const next = {...previousValue};
    if(params.get(currentValue)) {
      next[currentValue] = params.get(currentValue);
    }
    return next;
  }, {});

  store.dispatch(updateCommon(used));

  // ?hoff_transaction_id=1022dabd2a42bb6bef337c7fdca2d7&hoff_vendor_id=1574&hoff_offer_id=772&hoff_campaign_id={campaign_id}&hoff_aff_sub1=&hoff_aff_sub2=&hoff_aff_sub3=&hoff_aff_sub4=&hoff_aff_sub5=&url_id=0&gclid=&pwe_seg={pwe_seg}&utm_source={utm_source}&utm_medium={utm_medium}&utm_term={utm_term}&utm_campaign={utm_campaign}&utm_content={utm_content}
  
  // add an iframe link
  const {Analytics, Auth} = store.getState();
  const lang = Auth.lang || "en";
  const trackingUrl = process.env.REACT_APP_TRACKING_URL;

  const commonAttributesEncoded = Object.keys(Analytics.commonAttributes).reduce((previousValue, currentValue) => { return `${previousValue}${currentValue}=${Analytics.commonAttributes[currentValue]}&`}, '?');
  const iFrameUrl = `${trackingUrl.replace('LANGUAGE', lang)}${commonAttributesEncoded}`;

  const frameEl = document.createElement('iframe');
  frameEl.style.position = "absolute";
  frameEl.style.width = "0";
  frameEl.style.height = "0";
  frameEl.style.border = "none";
  
  document.body.append(frameEl);
  frameEl.setAttribute('src', iFrameUrl);
}

export { InitAnalytics }
