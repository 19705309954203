import { createGlobalStyle } from "styled-components";
import { FONTS, responsive } from "./Constants";

import AlegreyaRegular from "Assets/Fonts/Alegreya-Regular.ttf";
import AlegreyaMedium from "Assets/Fonts/Alegreya-Medium.ttf";
import AlegreyaBold from "Assets/Fonts/Alegreya-Bold.ttf";
// import AlegreyaBlack from 'Assets/Fonts/Alegreya-Black.ttf';
import AlegreyaSemiBold from "Assets/Fonts/Alegreya-SemiBold.ttf";
import RobotoPrimary from "Assets/Fonts/Roboto-Regular.ttf";
import RobotoBold from "Assets/Fonts/Roboto-Bold.ttf";
import RobotoCondensed from "Assets/Fonts/RobotoCondensed-Regular.ttf";
import GaldeanoRegular from "Assets/Fonts/Galdeano-Regular.ttf";

import CrimsonRegular from "Assets/Fonts/CrimsonText-Regular.ttf";
import CrimsonBold from "Assets/Fonts/CrimsonText-Bold.ttf";
import CrimsonSemiBold from "Assets/Fonts/CrimsonText-SemiBold.ttf";

export const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }
    html,
    body{
        background-color    : #F4F7F8;
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
        margin              : 0;
        padding             : 0;
    }
    #root {
        font-family         : ${FONTS.PRIMARY};
    }
    #onetrust-consent-sdk {
        font-family: ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
    :root {
        scrollbar-color: #d5d5d5 transparent;
        scrollbar-width: thin;
        --site-footer-background: #0c0c0c;
        --footer-social-background: rgb(27, 27, 27);
        --theme-color: #E32733;
    }

    ::-webkit-scrollbar {
        z-index: 1000;
        height: 6px;
        width: 6px;
        background: transparent;
    }
    ::-webkit-scrollbar * {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        z-index: 1000;
        border-radius: 6px;
        width: 6px;
        background: #d5d5d5;
    }

    ::-webkit-scrollbar-thumb:hover {
        z-index: 1000;
        background: #aeaeae;
    }

    ::-webkit-scrollbar-track {
        z-index: 1000;
        width: 6px;
        background: transparent;
    }
    
    .full-height{
        height: 100%;
    }
    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }
    .f-v-center{
        align-items: center;
    }
    .f-h-center{
        justify-content: center;
    }

    .text-center{
        text-align: center;
    }

    .visible-md{
        display: none;
        ${responsive.TABLET`display:block`}
    }
    .hidden-md{
        ${responsive.TABLET`display:none`}
    }
    .plain-notification{
        background-color: #fff;
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }
    
    .no-data-text {
        color       : #707070;
        font-size   : 15px;
        text-align  : center;
        width       : 100%;
    }

    .popover-root-races {
        width : 280px;
        margin : 10px 0;
        
        .dropdown-content {
            height : 150px;
            overflow : auto;
            .key {
                border-bottom: 1px solid #666;
                padding: 5px 10px;
                &:hover {
                    cursor: pointer;
                }
                &:last-child {
                    border-bottom : 1px solid transparent;
                }
            }
        }
    }

    .popover-root-shop {
        width : max-content;
        margin : 10px 0;

        .dropdown-content {

            .item {
                font-size: 18px;
                padding: 10px;
                &:hover {
                    cursor: pointer;
                    background-color: #e0e0e03b;
                }
            }
        }
    }

    .progress-root {
        display: flex;
        margin : 0 auto;
        color : #e32733;
    }

    .to-uppercase {
        text-transform: uppercase;
    }

    a#CybotCookiebotDialogPoweredbyCybot,
    div#CybotCookiebotDialogPoweredByText {
    display: none;
    }

    #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
        display: none;
    }

    @font-face {
        font-family : ${FONTS.PRIMARY};
        src         : url(${CrimsonRegular});
    }
    @font-face {
        font-family : ${FONTS.PRIMARY_LIGHT};
        src         : url(${CrimsonRegular});
    }
    @font-face {
        font-family : ${FONTS.PRIMARY_BOLD};
        src         : url(${CrimsonBold});
    }
    @font-face {
        font-family : ${FONTS.PRIMARY_MEDIUM};
        src         : url(${CrimsonRegular});
        font-size: 500;
    }
    @font-face {
        font-family : ${FONTS.SECONDARY_BOLD};
        src         : url(${CrimsonSemiBold});
    }
    /* @font-face {
        font-family : ${FONTS.PRIMARY};
        src         : url(${AlegreyaRegular});
    }
    @font-face {
        font-family : ${FONTS.PRIMARY_LIGHT};
        src         : url(${AlegreyaRegular});
    }
    @font-face {
        font-family : ${FONTS.PRIMARY_BOLD};
        src         : url(${AlegreyaBold});
    }
    @font-face {
        font-family : ${FONTS.PRIMARY_MEDIUM};
        src         : url(${AlegreyaMedium});
    } */
    /* @font-face {
        font-family : ${FONTS.SECONDARY_BOLD};
        src         : url(${AlegreyaSemiBold});
    } */
    @font-face {
        font-family : ${FONTS.SECONDARY};
        src         : url(${RobotoPrimary});
    }
    @font-face {
        font-family : ${FONTS.SECONDARY_BOLD};
        src         : url(${RobotoBold});
    }
    @font-face {
        font-family : ${FONTS.SECONDARY_CONDENSED};
        src         : url(${RobotoCondensed});
    }
    @font-face {
        font-family : ${FONTS.GALDEANO};
        src         : url(${GaldeanoRegular});
    }
    .footer-section__link-full-wrap {
        width: 100%;
        text-align: center;
        white-space: normal;
    }
`;
