import React from "react";
import { useState, useEffect } from "react";
import { NewsBodyWrapper, NewsBodyFilterIcons } from "./News.style";
import NewsContent from "./NewsContent";
import { ReactComponent as ViewMoreButton } from "Assets/images/News/viewMoreButton.svg";
import BackToTop from "Components/Commons/BackToTop/BackToTop";
import SocialContent from "./SocialContent";
import { FormControl, Select, MenuItem, Button, CircularProgress } from "@material-ui/core";
import { ContainerWrapper } from "Components/Website.style";
import { useTranslation } from "react-i18next";
import Api from 'Helpers/ApiHandler';
import { useSelector } from "react-redux";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const NEWS_INCREMENT = 4;
const SOCIAL_INCREMENT = 4;
const LIMIT = 20;

const hashToFilterMap = {
    "community": "nw-community",
    "nw-community": "nw-community",
    "media": "nw-media",
    "nw-media": "nw-media",
    "dev-blog": "dev-blog",
    "patch-notes": "patch-notes",
};

export default function NewsBody() {
    const { t: translate } = useTranslation();
    const [moreNews, setMoreNews] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [platform, setPlatform] = useState("");
    const [newsLength, setNewsLength] = useState(NEWS_INCREMENT);
    const [socialLength, setSocialLength] = useState(SOCIAL_INCREMENT);
    const [newsData, setNewsData] = useState([]);
    const [socialList, setSocialList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const language = useSelector(state => state.Auth.lang);

    const windowIsWide = windowDimensions.width > 960;

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        getSocialList()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, [language]);

    useEffect(() => {
        function handleHashChange() {
            const hashArray = window.location.hash.slice(1).split(",").slice(0, 2); // Only grab first 2
            let filterHash = "";
            let platformHash = "";

            for (const hash of hashArray) {
                // Check for filter hash
                if (Object.keys(hashToFilterMap).includes(hash)) {
                    filterHash = hashToFilterMap[hash];
                } else if (["pc", "xbox", "ps"].includes(hash)) { // Check for platform hash
                    platformHash = hash;
                }
            }
            setSelectedCategory(isAllTag(filterHash) ? "*" : filterHash);
            setPlatform(platformHash);
        }
        window.addEventListener("hashchange", handleHashChange);
        handleHashChange();
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, []);

    useEffect(() => {
        if (newsData.length > 0) {
            setNewsData([]);
        }
        if (selectedCategory !== null) {
            setLoading(true);
            getNews().then((response) => {
                if (response?.news && Array.isArray(response.news)) {
                    setNewsData(response.news);
                    setMoreNews(response.more_news);
                    setNewsLength(NEWS_INCREMENT);
                } else {
                    setNewsData([]);
                    setMoreNews(false);
                }
            });
        }
        // eslint-disable-next-line
    }, [selectedCategory, platform, language]);

    const getNews = async (offset = 0) => {
        let params = {
            tag: selectedCategory ?? "*",
            limit: LIMIT,
            offset: offset,
            field: ["images.img_microsite_thumbnail", "platforms", "updated"]
        };

        if (platform !== null) {
            params["platform"] = platform;
        }

        let response = await new Api().get(`games/nw/news`, {
            params: params,
            skipAuth: true,
        });

        setLoading(false);
        return response;
    }

    const isAllTag = (tagToTest) => {
        return ["", "all", "*"].includes(tagToTest.toLowerCase()) ;
    }

    const formatHash = (cat, plat) => {
        let useComma = plat !== "" && !isAllTag(cat);
        // Need to use * for all when no platform is selected since empty hash will cause a jump up
        return `${useComma ? `${cat},${plat}` : (isAllTag(cat) ? (plat === "" ? "*" : plat) : cat)}`;
    }

    const handleFilterChange = (value) => {
        window.location.hash = formatHash(value, platform);
    }
    const handlePlatformChange = (value) => {
        window.location.hash = formatHash(selectedCategory, platform === value ? "" : value);
    }

    const getSocialList = async () => {
        let response = await new Api().get("games/nw/socialfeeds", {
            params: {
                limit: 100, // Limit is always be 100 regardless of what we put here
                offset: 0,
                field: ["images", "user_screen_name", "url", "text", "user_full_name", "published"]
            },
            skipAuth: true,
        })
        setSocialList(response)
    }

    const handleFetchMore = () => {
        // Fetch more news data in the background when # of news currently shown is at least 10 away from what's stored in newsData
        if (newsLength > newsData.length - 10 && moreNews) {
            const offset = Math.ceil(newsLength / LIMIT) * LIMIT;
            getNews(offset).then((response) => {
                if (response?.news && Array.isArray(response.news)) {
                    setNewsData([...newsData, ...response.news]);
                    setMoreNews(response.more_news);
                }
            });
        }
    }

    const handleViewMoreAll = () => {
        handleFetchMore();
        setNewsLength(newsLength + NEWS_INCREMENT);
        setSocialLength(socialLength + SOCIAL_INCREMENT);
    }

    return (
        <NewsBodyWrapper >
            <ContainerWrapper newsBody={true} style={{ zIndex: "1" }}>
                <div className="news-body-container flex news-body-padding-bottom">
                    <div className="news-body-content">
                        <NewsPlatformFilter handlePlatformChange={handlePlatformChange} platform={platform} />
                        {windowIsWide ? <NewsFilterBig handleFilterChange={handleFilterChange} selectedCategory={selectedCategory} /> : <NewsFilterForm selectedCategory={selectedCategory} handleChange={(event) => handleFilterChange(event.target.value)} /> }
                        <div className="news-list-container">
                            {selectedCategory !== null && <NewsContent
                                selectedCategory={selectedCategory}
                                platform={platform}
                                newsLength={newsLength}
                                setLoading={setLoading}
                                loading={loading}
                                newsData={newsData}
                            />}
                            {!windowIsWide && newsData?.length > newsLength &&
                                <Button className="view-more-button" onClick={() => {handleFetchMore(); setNewsLength(newsLength + NEWS_INCREMENT);}}>{translate('news.view-more')}</Button>
                            }
                        </div>
                    </div>
                    <div className="news-body-social-container">
                        <div className="social-header flex header">
                            {translate('news.social')}
                        </div>
                        <div className="social-list-container">
                            {socialList?.slice(0, socialLength).map(item =>
                                <SocialContent item={item} key={item.id} />
                            )}
                            {!windowIsWide &&
                                <Button className="view-more-button" onClick={() => setSocialLength(socialLength + SOCIAL_INCREMENT)}>{translate('news.view-more')}</Button>
                            }
                        </div>
                    </div>
                </div>
            </ContainerWrapper>

            {windowIsWide && (newsData?.length > newsLength || socialList.length > socialLength) &&
                <div className="view-more-text-container flex f-v-center f-h-center"
                    onClick={() => handleViewMoreAll(newsLength, socialLength)}
                >
                    {loading && <CircularProgress classes={{ root: "progress-root" }} />}
                    <div className="view-more-text">
                        {translate('news.view-more')}
                    </div>
                    <div className="view-more-icon">
                        <ViewMoreButton />
                    </div>
                </div>
            }
            
            <BackToTop color="#000" />
        </NewsBodyWrapper>
    )
}

const NewsPlatformFilter = ({handlePlatformChange, platform}) => {
    const { t: translate } = useTranslation();

    return (
        <div className="body-header-container flex f-v-center header" style={{ flexDirection: 'row' }}>
            <div>{translate('navbar.news')}</div>
            <div className="filter-container flex f-v-center">{translate('news.filter')}:
                <NewsBodyFilterIcons className="flex">
                    <div className="icon-container flex">
                        {/* window */}
                        <div onClick={() => handlePlatformChange("pc")} className={`icon window-icon${platform === "pc" ? " icon--selected" : ""}`} />
                        {/* xbox  */}
                        <div onClick={() => handlePlatformChange("xbox")} className={`icon xbox-icon${platform === "xbox" ? " icon--selected" : ""}`} />
                        {/* ps */}
                        <div onClick={() => handlePlatformChange("ps")} className={`icon ps-icon${platform === "ps" ? " icon--selected" : ""}`} />
                    </div>
                </NewsBodyFilterIcons>
            </div>
        </div>
    )
}

const NewsFilterBig = ({handleFilterChange, selectedCategory}) => {
    const { t: translate } = useTranslation();

    return (
        <div className="body-sub-header-container flex">
            <div className={`body-sub-header ${selectedCategory === "*" ? "body-sub-header--selected" : ""}`} onClick={() => handleFilterChange("*")}>
                <span>{translate('news.all')}</span>
            </div>

            <div className={`body-sub-header ${selectedCategory === "nw-community" ? "body-sub-header--selected" : ""}`} onClick={() => handleFilterChange("nw-community")}>
                <span>{translate('news.community')}</span>
            </div>

            <div className={`body-sub-header ${selectedCategory === "nw-media" ? "body-sub-header--selected" : ""}`} onClick={() => handleFilterChange("nw-media")}>
                <span>{translate('news.media')}</span>
            </div>

            <div className={`body-sub-header ${selectedCategory === "dev-blog" ? "body-sub-header--selected" : ""}`} onClick={() => handleFilterChange("dev-blog")}>
                <span>{translate('news.dev-blog')}</span>
            </div>

            <div className={`body-sub-header ${selectedCategory === "patch-notes" ? "body-sub-header--selected" : ""}`} onClick={() => handleFilterChange("patch-notes")}>
                <span>{translate('news.patch-notes')}</span>
            </div>
        </div>
    )
}


const NewsFilterForm = ({selectedCategory, handleChange}) => {
    const { t: translate } = useTranslation();
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        const handleScroll = event => {
            setFilterOpen(false);
        };
      
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleFilterClose = () => {
        setFilterOpen(false);
    }
    const handleFilterOpen = () => {
        setFilterOpen(true);
    }

    return (
        <FormControl variant="outlined" fullWidth className={"body-sub-header-select"}>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedCategory}
                onChange={handleChange}
                open={filterOpen}
                onClose={handleFilterClose}
                onOpen={handleFilterOpen}
                MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                <MenuItem selected value={"*"}><span className="to-uppercase">{translate('news.all')}</span></MenuItem>
                <MenuItem value={"nw-community"}><span className="to-uppercase">{translate('news.community')}</span></MenuItem>
                <MenuItem value={"nw-media"}><span className="to-uppercase">{translate('news.media')}</span></MenuItem>
                <MenuItem value={"dev-blog"}><span className="to-uppercase">{translate('news.dev-blog')}</span></MenuItem>
                <MenuItem value={"patch-notes"}><span className="to-uppercase">{translate('news.patch-notes')}</span></MenuItem>
                {/* <MenuItem value={"platform"}>{translate('news.platform')}</MenuItem> */}
            </Select>
        </FormControl>
    )
}