import React from "react";
import { NewsContentWrapper, NewsBodyIcons } from "./News.style";
import { useHistory, withRouter } from "react-router-dom";
import FallbackImage from "Assets/images/News/news-fallback.webp";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


function NewsContent(props) {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const language = useSelector(state => state.Auth.lang);

    const getDateText = (dateString) => {
        const date = new Date(dateString);
        return `${date.toLocaleDateString(language)} ${date.toLocaleTimeString(language, { hour: "2-digit", minute: "2-digit" })}`;
    }

    const handleNewsContentClick = value => {
        history.push({ pathname: "/" + language + "/news-details/" + value.id, state: { ...value } })
    }

    const getPlatform = (platforms) => {
        let filterData = {
            'xbox': <div className="icon xbox-icon no-hover" />,
            "ps": <div className="icon ps-icon no-hover" />,
            "pc": <div className="icon window-icon no-hover" />,
        }

        let filterArray = []

        for (let i = 0; i < platforms?.length; i++) {
            filterArray.push(filterData[platforms[i]])
        }

        return filterArray
    }

    const getCategoryTag = (item) => {
        if (!item?.tags?.length) return null;

        const tagData = {
            "nw-community": translate("news.community"),
            "nw-media": translate("news.media"),
            "dev-blog": translate("news.dev-blog"),
            "patch-notes": translate("news.patch-notes"),
        }

        if (Object.keys(tagData).includes(props.selectedCategory)) {
            return tagData[props.selectedCategory];
        } else {
            for (let tag of item.tags) {
                if (tagData.hasOwnProperty(tag)) {
                    return tagData[tag];
                }
            }
        }

        return null;
    }

    return (
        <React.Fragment>
            {props.newsData && props.newsData.slice(0, props.newsLength).map(news =>
                <NewsContentWrapper style={{}} className="flex full-width" onClick={() => handleNewsContentClick(news)}>
                    <div className="news-content-image full-width">
                        <div className="news-image" style={{ backgroundImage: `url(${news?.images?.img_microsite_thumbnail?.url ?? FallbackImage})` }} />
                    </div>
                    <div className="news-content-text flex">
                        <div className="flex f-v-center">
                            <div className="news-content-header">
                                {getCategoryTag(news)}
                            </div>
                            <NewsBodyIcons className="flex">
                                {getPlatform(news.platforms)}
                            </NewsBodyIcons>
                        </div>
                        <p className="news-content-main-text">
                            {news.title}
                        </p>
                        <p className="news-content-main-date">
                            {getDateText(news?.updated)}
                        </p>
                        <p className="news-content-sub-text">
                            {news.summary}
                        </p>

                    </div>
                </NewsContentWrapper>
            )}
            {<p className="news-status-text">
                {props.loading && translate("news.loading")}
                {!props.loading && props.newsData.length === 0 && translate("news.unavailable")}
            </p>}
        </React.Fragment>
    )
}

export default withRouter(NewsContent);