import crypto from "crypto";
import { formatDistance, parseISO } from "date-fns";
import en from "date-fns/locale/en-US";
import { de, fr, ru } from "date-fns/locale";

const locales = {
  en,
  de,
  fr,
  ru,
}

export const isEqualsArray = (arr1, arr2) => {
  if (!(arr1 instanceof Array) || !(arr1 instanceof Array)) return false;

  if (arr1.length !== arr2.length) return false;

  if (arr1 instanceof Object && arr2 instanceof Object) {
    let isEqual = true,
      arrayLength = arr1.length;
    for (let index = 0; index < arrayLength && isEqual; index++) {
      if (JSON.stringify(arr1[index]) !== JSON.stringify(arr2[index]))
        isEqual = false;
    }

    return isEqual;
  }

  return false;
};

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function isIEBrowser() {
  // BROWSER CHECK VARIABLES
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const msie11 = ua.indexOf("Trident/");
  // const msedge = ua.indexOf('Edge/');
  return msie > 0 || msie11 > 0;
  // const isEdge = msedge > 0;
}

export function generateCodeVerifier(length) {
  if (length < 43 || length > 128) {
    throw new Error("Length must be between 43 and 128 characters");
  }
  // Generate a crytographically secure buffer of bytes to choose our characters
  const buf = crypto.randomBytes(length * 4);
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  const charactersLength = characters.length;
  const chars = [];

  // Choose characters at random to build the string
  for (let i = 0; i < length; i += 1) {
    chars[i] = characters.charAt(buf.readUInt32LE(i * 4, 4) % charactersLength);
  }

  // Return the string
  return chars.join("");
}

export function generateCodeChallenge(codeVerifier) {
  if (codeVerifier.length < 43 || codeVerifier.length > 128) {
    throw new Error(
      "Code verifier length must be between 43 and 128 characters"
    );
  }

  // SHA256 hash the codeVerifier and then convert the Base64 result into a URL friendly format
  const hash = crypto.createHash("sha256");
  const challenge = hash
    .update(codeVerifier)
    .digest("base64")
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");

  return challenge;
}

export function getTimeAgo(published, language) {
  const currentTime = new Date(new Date().toUTCString().substr(0, 25));
  return formatDistance(parseISO(published), currentTime, { addSuffix: true, locale: locales[language] });
}
