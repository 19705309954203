import { ContainerWrapper } from "Components/Website.style";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    StoryContainer
} from "./Home.style";

function Story() {
    const { t } = useTranslation();
    return (
        <StoryContainer>
            <div className="bg-image">
                <ContainerWrapper>
                    {/* <div className="border-box top" /> */}
                    <div className="container">
                        <div className="title">{t('home.header.story')}</div>
                        <div className="text">{t("home.story")}
                        </div>
                    </div>
                    {/* <div className="border-box bottom" /> */}
                </ContainerWrapper>
            </div>
        </StoryContainer>
    )
}

export default Story;
