import React from "react";
import { NewsDetailsBodyWrapper } from "./NewsDetails.style";
// import NewsDetailsImage from "Assets/images/News/NewsDetails.png";
import { Button } from "@material-ui/core";
import TweeterLogo from "Assets/images/News/tweeter.png";
import FacebookLogo from "Assets/images/News/facebook.png";
import Carousel from "react-material-ui-carousel";
import RelatedNews from "./RelatedNews";
import FallbackImage from "Assets/images/News/news-fallback.webp";
import { COLORS } from "Styles/Constants";
import { ReactComponent as NextIcon } from "Assets/images/News/next.svg";
import { ReactComponent as PreviousIcon } from "Assets/images/News/previous.svg";
import { useEffect } from "react";
import { useState } from "react";
import { ContainerWrapper } from "Components/Website.style";
import moment from "moment";
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/ru';
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import Api from "Helpers/ApiHandler";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";
import BackToTop from "Components/Commons/BackToTop/BackToTop";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function NewsDetailsBody(props) {
    const { newsArticleData, getNewsArticleData } = props;
    const { t: translate } = useTranslation()
    const history = useHistory();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [relatedNews, setRelatedNews] = useState([]);
    const [relatedNewsIndex, setRelatedNewsIndex] = useState(0);
    const [mobileNewsShown, setMobileNewsShown] = useState(2);
    const [loading, setLoading] = useState(false);
    const language = useSelector(state => state.Auth.lang);
    const [rightArrowHover, setRightArrowHover] = useState(false);
    const [leftArrowHover, setLeftArrowHover] = useState(false);
    const { REACT_APP_ACCOUNT_URL } = process.env;
    const [author, setAuthor] = useState("");
    const [updateDate, setUpdateDate] = useState("");

    const validFilters = {
        "nw-community": translate("news.community"),
        "nw-media": translate("news.media"),
        "dev-blog": translate("news.dev-blog"),
        "patch-notes": translate("news.patch-notes"),
    }

    const handleBreadcrumbs = (filter = null) => {
        history.push({
            pathname: `/${language}/news`,
            hash: filter ? `#${filter}` : ""
        })
    }

    const rightArrowMouseInandler = () => {
        setRightArrowHover(true);
    }

    const rightArrowMouseOutHandler = () => {
        setRightArrowHover(false);
    }

    const leftArrowMouseInandler = () => {
        setLeftArrowHover(true);
    }

    const leftArrowMouseOutHandler = () => {
        setLeftArrowHover(false);
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        moment.locale(language);
        setAuthor(newsArticleData.author);
        setUpdateDate(moment(newsArticleData.updated).format("MMMM DD, YYYY hh:mm A"));
        if (newsArticleData?.tags) {
            getRelatedNews(newsArticleData?.tags);
        }
        // eslint-disable-next-line
    }, [newsArticleData, language]);


    const getRelatedNews = async (tags) => {
        const relatedTag = tags.find(tag => validFilters.hasOwnProperty(tag)) || "";
        setLoading(true);
        let response = await new Api().get("games/nw/news?field[]=images.img_microsite_thumbnail", {
            params: {
                limit: 11,
                offset: 0,
                tag: relatedTag,
            },
            skipAuth: true,
        });
        const relatedNewsArray = response.news.filter((newsItem) => newsItem.id !== newsArticleData?.id);
        setRelatedNews(relatedNewsArray);
        setLoading(false);
    }

    const getNext = () => {
        const lastIndex = relatedNews.length - 1;
        if (relatedNewsIndex + 2 > lastIndex) {
            setRelatedNewsIndex(0);
        } else {
            setRelatedNewsIndex(index => index + 2);
        }
    }

    const getPrev = () => {
        const lastIndex = relatedNews.length - 1;
        if (relatedNewsIndex - 2 < 0) {
            if (lastIndex % 2 === 0) {
                setRelatedNewsIndex(lastIndex);
            } else {
                setRelatedNewsIndex(lastIndex - 1);
            }
        } else {
            setRelatedNewsIndex(index => index - 2);
        }
    }

    const getHeaderFilter = () => {
        let innerArray = []
        if (newsArticleData?.tags?.length) {
            newsArticleData.tags.forEach(element => {
                if (element === "dev-blog") {
                    innerArray.push(
                        <div onClick={() => handleBreadcrumbs("dev-blog")} className="body-sub-header">
                            <span>{translate("news.dev-blog")}</span>
                        </div>
                    )
                } else if (element === "patch-notes") {
                    innerArray.push(
                        <div onClick={() => handleBreadcrumbs("patch-notes")} className="body-sub-header">
                            <span>{translate("news.patch-notes")}</span>
                        </div>
                    )
                } else if (element === "nw-community") {
                    innerArray.push(
                        <div onClick={() => handleBreadcrumbs("nw-community")} className="body-sub-header">
                            <span>{translate("news.community")}</span>
                        </div>
                    )
                } else if (element === "nw-media") {
                    innerArray.push(
                        <div onClick={() => handleBreadcrumbs("nw-media")} className="body-sub-header">
                            <span>{translate("news.media")}</span>
                        </div>
                    )
                }
            });
        }
        return innerArray;
    }

    return (
        <NewsDetailsBodyWrapper>
            <div className="news-details-body-container flex f-v-center f-h-center full-width">
                <ContainerWrapper>
                    <div className="news-details-header-container flex f-v-center f-h-center ">
                        <div className="news-details-header-image flex f-v-center f-h-center">
                            <div className="border-top" />
                            <div className="right" />
                            <div className="bottom-left" />
                            <div className="bottom-right" />
                            <div style={{ backgroundImage: `url(${newsArticleData?.images?.img_microsite_background?.url ?? FallbackImage})` }} className="news-details-image" />
                        </div>
                    </div>
                </ContainerWrapper>
                <ContainerWrapper style={{ zIndex: 2 }}>
                    <div className="news-body-content">
                        <div className="body-sub-header-container flex">
                            <div onClick={() => handleBreadcrumbs("")} className="body-sub-header">
                                <span>{translate("news.all")}</span>
                            </div>
                            {getHeaderFilter()}
                        </div>

                        <div className="news-body-title-container">
                            <div className="news-body-title">
                                {newsArticleData.title}
                            </div>
                            <div className="news-body-sub-title">
                                {newsArticleData.summary}
                            </div>
                            <div className="news-body-by">
                                <Trans i18nKey="news.details.author.date">
                                    By <strong>{{author}}</strong> {{updateDate}}
                                </Trans>
                            </div>
                        </div>
                        <div className="news-body-text-container flex f-v-center f-h-center">
                            <div className="news-body">
                                <div dangerouslySetInnerHTML={{ __html: newsArticleData.content }} />
                            </div>
                        </div>
                    </div>
                </ContainerWrapper>
            </div>
            <div>
                <div className="news-details-footer-container flex f-v-center f-h-center full-width">
                    <ContainerWrapper>
                        <div style={{ position: 'relative', zIndex: "4" }} className="news-details-footer-content flex f-v-center f-h-center full-width">
                            <Button
                                className="discuss-button"
                                onClick={() => window.open(`${REACT_APP_ACCOUNT_URL}/${language}/forums/neverwinter`, "_blank")}
                            >
                                {translate("home.news.article.discuss")}
                            </Button>
                            <div className="news-details-footer-links full-width flex f-v-center">
                                <span style={{ textTransform: 'uppercase' }}>{translate('news.details.share')}:
                                </span>
                                <FacebookShareButton url={window.location.href} className="flex f-v-center"><span title="Facebook"><img src={FacebookLogo} alt="" /></span></FacebookShareButton>
                                <TwitterShareButton url={window.location.href} style={{ marginLeft: '5px' }} className="flex f-v-center"><span title="Twitter"><img src={TweeterLogo} alt="" /></span></TwitterShareButton>
                            </div>
                        </div>
                        <div className="mobile-border" />
                    </ContainerWrapper>
                </div>
                <div className="border" />
            </div>
            {!loading && relatedNews.length > 0 && 
                <div className="related-news-container flex f-h-center f-v-center">
                    <ContainerWrapper>
                        <div className="related-news-content-container">
                            <div className="flex f-v-center related-news-header full-width">
                                {translate('news.related.news')}
                            </div>
                            {loading && <div className="related-news-loading"><CircularProgress classes={{ root: "progress-root" }} /></div>}
                            {windowDimensions.width < 600 && relatedNews.length > 0 &&
                                <div className="flex f-v-center f-h-center related-news-cards">
                                    {relatedNews.slice(0, mobileNewsShown).map(newsItem => (
                                        <RelatedNews newsItem={newsItem} getNewsArticleData={getNewsArticleData}/>
                                    ))}
                                    {mobileNewsShown < relatedNews.length && <Button onClick={() => setMobileNewsShown(newsShown => newsShown + 2)} className="view-more-button">{translate('news.view-more')}</Button>}
                                </div>
                            }
                            {windowDimensions.width >= 600 && relatedNews.length <= 2 &&
                                <div className="flex">
                                    {relatedNews.map(newsItem => <RelatedNews newsItem={newsItem} getNewsArticleData={getNewsArticleData} />)}
                                </div>
                            }
                            {windowDimensions.width >= 600 && relatedNews.length > 2 &&
                                <Carousel className="carousel-container"
                                    indicators
                                    showArrows={true}
                                    IndicatorIcon={<div />}
                                    navButtonsAlwaysVisible={true}
                                    indicatorIconButtonProps={{
                                        style: {
                                            display: "none",
                                            width: "62px",
                                            height: "8px",
                                            background: "white",
                                            marginLeft: '10px',    // 1
                                            color: 'blue',       // 3
                                            borderRadius: "10px"
                                        }
                                    }}
                                    activeIndicatorIconButtonProps={{
                                        style: {
                                            background: `${COLORS.SECONDARY}`,
                                        }
                                    }}
                                    indicatorContainerProps={{
                                        style: {
                                            display: "none",

                                            marginTop: '50px', // 5
                                            textAlign: 'center' // 4
                                        }
                                    }}
                                    setSelectedItems={2}
                                    autoPlay={false}
                                    navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                                        style: {
                                            backgroundColor: 'transparent',
                                            borderRadius: 0,
                                        },
                                    }}
                                    navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                                        style: {
                                            bottom: '0',
                                            top: 'unset',
                                            display: 'flex',
                                            marginBottom: "0.6rem"
                                        },
                                    }}
                                    NavButton={({ next, prev }) => {
                                        // Other logic
                                        return (
                                            <>
                                                {prev && <Button className="carousel-button" onClick={getPrev} onMouseEnter={leftArrowMouseInandler} onMouseLeave={leftArrowMouseOutHandler}>
                                                    <PreviousIcon fill={leftArrowHover ? "#E32733" : "black"} src={PreviousIcon} alt="previous" />
                                                </Button>}
                                                {next && <Button className="carousel-button" onClick={getNext} onMouseEnter={rightArrowMouseInandler} onMouseLeave={rightArrowMouseOutHandler}>
                                                    <NextIcon fill={rightArrowHover ? "#E32733" : "black"} src={NextIcon} alt="next" />
                                                </Button>}
                                            </>
                                        )
                                    }}
                                >
                                    <div className="flex f-v-center f-h-center ">
                                        <div className="related-news-content flex">
                                            <RelatedNews newsItem={relatedNews[relatedNewsIndex]} getNewsArticleData={getNewsArticleData} />
                                            {relatedNews[relatedNewsIndex + 1] && <RelatedNews newsItem={relatedNews[relatedNewsIndex + 1]} getNewsArticleData={getNewsArticleData} />}
                                        </div>
                                    </div>
                                </Carousel>
                            }
                        </div>
                    </ContainerWrapper>
                </div>
            }
            <BackToTop color="#000" />
        </NewsDetailsBodyWrapper>
    )
}