import { ACTION_TYPES } from "./Actions";

const initState = {
  isLoggedIn: false,
  accessToken: "",
  lang: "en",
  codeVerifier: "",
  profileInfo: {},
  totalScore: 0,
  userIP: "",
  gdprStatusChecked: false,
  isGdpr: true, // Default to true for gdpr since it's safer to show it in case of error
  isInternal: false,
  regionOverwrite : "",
  analyticsSettings: {
    checked: false,
    allow: false,
  }
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_USER:
      return {
        ...state,
        isLoggedIn: true,
        accessToken: action.accessToken,
      };

    case ACTION_TYPES.LOGOUT_USER:
      return state; // logout removes everything by swapping out reducers. See store.js
      /*
      return {
        // ...state,
        // isLoggedIn: false,
        ...initState,
        lang: state.lang
      };
      */
    case ACTION_TYPES.CODE_VERIFIER:
      return {
        ...state,
        codeVerifier: action.codeVerifier,
      };
    case ACTION_TYPES.ADD_LANG:
      return {
        ...state,
        lang: action.lang,
      };
    case ACTION_TYPES.UPDATE_LANG:
      return {
        ...state,
        lang: action.lang,
      };
    case ACTION_TYPES.UPDATE_PROFILE:
      return {
        ...state,
        profileInfo: action.profileInfo,
      };

    case ACTION_TYPES.UPDATE_TOTAL_SCORE:
      return {
        ...state,
        totalScore: action.score,
      };

    case ACTION_TYPES.USER_IP:
      return {
        ...state,
        userIP: action.userIP,
      };
  
    case ACTION_TYPES.SET_IS_GDPR:
      return {
        ...state,
        isGdpr : action.isGdpr,
        gdprStatusChecked: true,
      };

    case ACTION_TYPES.SET_IS_INTERNAL:
      return {
        ...state,
        isInternal : action.isInternal
      };

    case ACTION_TYPES.SET_REGION_OVERWRITE:
      return {
        ...state,
        regionOverwrite : action.regionOverwrite
      };
    default:
      return state;
  }
};

export default Reducer;
