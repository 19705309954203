import { ContainerWrapper } from 'Components/Website.style';
import React, { useState } from 'react';
import { ForumWrapper } from './Forum.style';
import { InputAdornment, OutlinedInput } from '@material-ui/core';
import SearchIcon from "Assets/images/Forum/search.png";

function ForumHeader() {

    const [paths] = useState([
        'Forums', 'Home'
    ]);

    return (
        <ForumWrapper>
            <ContainerWrapper>
                <div className="notice">{"NOTICE: Read the Forum Rules and check for"} <span className="danger">{"Known Issues"}</span> {"before posting!"}</div>

                <div className="top-section">
                    <div className="path">
                        {
                            paths.map((item, index) =>
                                <div className="path-section" key={index}>
                                    <div className="path-name">{item}</div>
                                    {(paths.length - 1) !== index && <div className="arrow-icon" />}
                                </div>
                            )
                        }
                    </div>
                    <div className="search-section">
                        <OutlinedInput
                            className="search-input"
                            placeholder="search"
                            classes={{
                                notchedOutline: "notchedOutline-search-box"
                            }}
                            startAdornment={<InputAdornment position="start"><img src={SearchIcon} alt="search" className="search-icon" /></InputAdornment>}
                        />
                    </div>

                </div>
                <div className="tab-section">
                    <div className="title">{"Categories"}</div>
                    <div className="divider" />
                    <div className="title">{"Discussions"}</div>
                    <div className="divider" />
                    <div className="title">{"Mark All Viewed"}</div>
                </div>

            </ContainerWrapper>
        </ForumWrapper>
    )

}

export default ForumHeader;