import React, { useEffect, useState } from "react";
import { NewsContainer } from "./News.style";
import Carousel from "react-material-ui-carousel";
import { COLORS } from "Styles/Constants";
import HeaderContent from "./HeaderContent";
import NewsBody from "./NewsBody";
import Api from 'Helpers/ApiHandler';
import { useSelector } from "react-redux"

function News() {
    const [newsListCarousel, setNewsListCarousel] = useState([])
    const language = useSelector(state => state.Auth.lang);

    useEffect(() => {
        getNewsListCarousel();
    }, [language])

    const getNewsListCarousel = async () => {
        let response = await new Api().get("games/nw/newsbanners", {
            params: {
                type: "news_featured",
                field: ["media"]
            },
            skipAuth: true,
        });
        setNewsListCarousel(response)
    }

    return (
        <React.Fragment>
            <NewsContainer>
                <div className="bg-image">
                    <Carousel showThumbs={false} className="carousel-container"
                        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                            style: {
                                display: "none"
                            }
                        }}
                        IndicatorIcon={<div />}

                        indicatorIconButtonProps={{
                            style: {
                                width: "62px",
                                height: "8px",
                                background: "white",
                                marginLeft: '16px',    // 1
                                borderRadius: "10px",
                                '&:hover': {
                                    background: `${COLORS.SECONDARY}`,
                                }
                            }
                        }}
                        activeIndicatorIconButtonProps={{
                            style: {
                                background: `${COLORS.SECONDARY}`,
                            }
                        }}
                        indicatorContainerProps={{
                            style: { textAlign: 'center' }

                        }}
                        autoPlay={true}
                    >
                        {newsListCarousel && newsListCarousel.map(item => (
                            <HeaderContent key={item.id} item={item} />
                        ))}

                    </Carousel>
                </div>
                <NewsBody />
            </NewsContainer>
        </React.Fragment>
    )
}

export default News;