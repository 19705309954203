import { throttle } from 'lodash';
const CURRENT_VERISON = "2";
// maybe we should check out using redux-persist
const loadState = () => {
  if(localStorage.getItem('stateVersion') == null) {
    return undefined;
  }

  if(localStorage.getItem('stateVersion') !== CURRENT_VERISON) {
    return undefined;
  }

  // if stateVersion is -1, it's dirty, don't use it
  if(localStorage.getItem('stateVersion') === -1) {
    return undefined;
  }

  try {
    const serializedState = localStorage.getItem('state');
    if(serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = throttle((state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
    localStorage.setItem('stateVersion', CURRENT_VERISON);
  } catch (err) {
    console.warn("State save error");
  }
}, 1000);

// On redirect, flush saveState so that anything throttled is saved
window.addEventListener("beforeunload", (event) => {
  saveState.flush();
}, {capture: true});

export {loadState, saveState}