import React from "react";
import { BackToTopContainer } from "./BackToTop.style.js";
import { ReactComponent as ScrollUpIcon } from "Assets/images/scroll-up.svg";

const BackToTop = ({ color="#000" }) => {

  const scrollToTop = () => {
    window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth"
    })
  };

  return (
    <BackToTopContainer className="flex f-v-center f-h-center" color={color}>
      <ScrollUpIcon className="back-to-top" onClick={scrollToTop} width="20px" height="30px" alt="" />
    </BackToTopContainer>
  )
}

export default BackToTop;