// CORE
import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';

import Toast from 'Components/Commons/Toast/Toast'
import { hideToast } from "Redux/General/Actions";
import { addLang } from "Redux/Auth/Actions";
import { withRouter } from 'react-router';
import i18n from 'i18';
import { store } from 'Redux/Store';
// import Api from 'Helpers/ApiHandler';

class EventManager extends Component {

    componentDidMount() {
        let path = this.props.history.location.pathname.split("/")[1];
        this.changeLanguage(path);
    }

    changeLanguage = (value) => {
        const langs = ['en', 'fr', 'de', 'it', 'ru'];
        let find = langs.find(item => item === value)
        if (find) {
            i18n.changeLanguage(value);
            store.dispatch(addLang({ lang: value }));
            document.documentElement.lang = value;
            return;
        }

        // language was inavlid, fallback
        document.documentElement.lang = "en";
        i18n.changeLanguage("en")
        store.dispatch(addLang({ lang: "en" }))
    }

    render() {

        return (
            <React.Fragment>
                <Toast
                    open={this.props.toast.enable}
                    message={this.props.toast.message}
                    handleClose={this.props.hideToast}
                />
                {
                    (this.props.isLoggedIn) &&
                    <AfterLoginEvents />
                }
            </React.Fragment>
        );
    }
}

function AfterLoginEvents() {
    // const dispatch = useDispatch();

    // eslint-disable-next-line
    useEffect(() => { init() }, [])

    async function init() {
        // GET PROFILE INFO
        // let response = await new Api().get("users/me");
    }
    return (
        <>
        </>
    )
}

const mapReduxStateToProps = state => {
    return {
        isLoggedIn: state.Auth.isLoggedIn,
        toast: state.General.toast,
    }
}

export default withRouter(connect(mapReduxStateToProps, {
    hideToast,
    addLang
})(EventManager));
