import styled from "styled-components";
import { COLORS, FONTS, responsive } from "Styles/Constants";

// import NewsBG from "Assets/images/News/NewsBG.svg";
// import BodyBackground from "Assets/images/News/BG_Texture_01.png";
import ViewMoreBackground from "Assets/images/News/ViewMoreBG.png";

import NewsHeaderBackgroundImage from "Assets/images/News/NewsHeaderBackgroundImage.png";
import NewsBackgroundImage from "Assets/images/News/NewsBackgroundImage.jpg";
// import Close from "Assets/images/News/close.svg";
import BorderLeft from "Assets/images/Home/BorderLeft.png";
import BorderRight from "Assets/images/Home/BorderRight.png";
import Border from "Assets/images/Home/border.png";
import RacesBorder from "Assets/images/Home/RacesBorder.png";

export const NewsDetailsWrapper = styled.div.attrs({
  className: 'NewsDetailsWrapper',
})`
  background-color: white;
  .bg-image {
    background: url(${NewsHeaderBackgroundImage});
    background-position: center center;
    background-size: cover;
    height: 100%;
    min-height: 550px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${responsive.LAPTOP`
      min-height: 577px;
    `}
    ${responsive.TABLET`
      min-height: 540px;
    `}

    ${responsive.MOBILE`
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 122px;
    `}

    @media (min-width: 2000px) {
      min-height: 818px;
    }
  }

  .close-button-top-right {
    position: absolute;
    top: 31px;
    right: 63px;
    color: white;
    cursor: pointer;
  }

  .news-back-to-top {
    margin-top: 16px;
  }
`;

export const NewsDetailsBodyWrapper = styled.div.attrs({
  className: 'NewsDetailsBodyWrapper',
})`
  margin-top: -70px;
  background: url(${NewsBackgroundImage});
  background-position: center top;
  background-size: 100%;
  background-repeat: repeat-y;
  padding: 0 20px;

  ${responsive.MOBILE`
    margin-top: -7px;
  `}
  .next-slide {
    &:hover {
      fill: #e32733;
    }
  }

  .prev-slide {
    &:hover {
      fill: #e32733;
    }
  }
  .mobile-border {
    ${responsive.MOBILE`
      background: url(${RacesBorder});
      background-repeat: no-repeat;
      background-size: contain;
      height: 50px;
    `}
  }

  .border {
    background: url(${Border});
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleX(-1);
    height: 295px;
    width: 90%;
    max-width: 1200px;
    margin: -300px auto 20px;
    z-index: 2;

    ${responsive.TABLET`
      height: 157px;
      right: 70px;
      margin-top: -180px;
    `}

    ${responsive.MOBILE`
      display: none;
    `}
  }

  .back-arrow {
    margin-left: -100px;
    margin-top: 20px;
    cursor: pointer;
  }

  .news-details-header-container {
    width: 100%;
    margin: -400px 0 0 0;
    ${responsive.LAPTOP`
            margin: -280px 0 0 0;
        `}
    ${responsive.TABLET`
      margin: -200px 0 0 0;
    `}
    ${responsive.PHABLET`
        margin: -100px 0 0 0;
    `}

    .news-details-header-image {
      width: 100%;
      padding: 20px;
      position: relative;

      .border-top {
        background: url(${BorderLeft});
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        height: 405px;
        right: 0;
        left: 3px;
        top: 6px;

        ${responsive.TABLET`
          height: 289px;
        `}

        ${responsive.PHABLET`
          height: 179px;
          left: 11px;
          top: 11px;
        `}

        ${responsive.MOBILE`
          height: 145px;
          top: 13px;
          left: 12px;
        `}
      }

      .right {
        background: url(${BorderRight});
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 5px;
        top: 6px;
        height: 355px;
        width: 559px;

        ${responsive.TABLET`
          width: 465px;
        `}

        ${responsive.PHABLET`
          top: 11px;
          height: 185px;
          width: 297px;
        `}

        ${responsive.MOBILE`
          width: 210px;
          top: 13px;
          right: 13px;
        `}
      }

      .bottom-left {
        background: url(${BorderRight});
        background-repeat: no-repeat;
        transform: rotate(180deg);
        background-size: contain;
        position: absolute;
        top: 205px;
        left: 3px;
        height: 431px;
        width: 594px;

        ${responsive.TABLET`
          height: 268px;
          width: 412px;
          top: 171px;
        `}

        ${responsive.PHABLET`
          height: 192px;
          width: 314px;
          top: 37px;
          left: -1px;
        `}

        ${responsive.MOBILE`
          height: 237px;
          width: 191px;
          top: -13px;
          left: 12px;
        `}
      }

      .bottom-right {
        background: url(${BorderLeft});
        background-repeat: no-repeat;
        transform: rotate(180deg);
        background-size: contain;
        position: absolute;
        right: 6px;
        top: 148px;
        height: 488px;
        width: 600px;

        ${responsive.TABLET`
          height: 268px;
          width: 412px;
          top: 171px;
          right: 5px;
        `}

        ${responsive.PHABLET`
          height: 147px;
          width: 264px;
          top: 81px;right: 11px;
        `}

        ${responsive.MOBILE`
          height: 237px;
          width: 191px;
          top: -13px;
          right: 13px;
        `}
      }

      .news-details-image {
        height: 600px;
        width: 100%;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;

        ${responsive.TABLET`
                    height : 400px;
                `}
        ${responsive.PHABLET`
                    height : 200px;
                `}
                ${responsive.MOBILE`
                    height : 200px;
                `}
      }
    }
  }

  .news-details-body-container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .news-body-content {
      width: 100%;
      z-index: 1;
      margin: 0 auto;

      .body-sub-header-container {
        .body-sub-header {
          cursor: pointer;
          margin-top: 24px;
          margin-bottom: 24px;
          padding-left: 24px;
          padding-right: 24px;
          border-left: 2px solid #bca87a;
          text-transform: uppercase;
          letter-spacing: 2px;
          &:first-child {
            border: none;
            padding-left: 0;
          }
          &:active,
          &:hover {
            span {
              color: #2d448a;
              border-bottom: 1px solid #2d448a;
            }
          }
        }
      }

      .body-sub-header-select {
        margin: 10px;
        max-width: 280px;
      }

      .news-body-title-container {
        .news-body-title {
          font-size: 48px;
          font-weight: bold;
          line-height: 60px;
          letter-spacing: 4px;
          color: #bca87a;
          text-transform: uppercase;

          ${responsive.TABLET`
                        font-size: 32px;
                        line-height: 36px;
                    `}

          ${responsive.PHABLET`
                        font-size: 24px;
                        line-height: 26px;
                    `}
        }
        .news-body-sub-title {
          padding-top: 16px;
          padding-bottom: 16px;
          font-size: 32px;
          line-height: 38px;
          ${responsive.TABLET`
                        font-size: 24px;
                        line-height: 26px;

                    `}
          ${responsive.PHABLET`
                        font-size: 18px;
                        line-height: 20px;

                    `}
        }
      }

      .news-body-text-container {
        flex-direction: column;
        padding-bottom: 24px;

        .news-body {
          width: 100%;
          font-size: 24px;
          line-height: 32px;
          ${responsive.TABLET`
                        font-size: 20px;
                        line-height: 28px;

                    `}
          ${responsive.PHABLET`
                        font-size: 16px;
                        line-height: 20px;
                    `}

                    img {
            max-width: 100%;
          }

          h5 {
            font-size: 32px;
            font-weight: bold;
            color: #bca87a;
            text-transform: uppercase;
            letter-spacing: 2px;

            ${responsive.TABLET`
                            font-size: 24px;
                            line-height: 30px;
    
                        `}
            ${responsive.PHABLET`
                            font-size: 20px;
                            line-height: 26px;
    
                        `}
          }

          iframe {
            width: 100%;
            height: 100%;
            aspect-ratio: 16 / 9;
          }
        }
      }
    }
  }

  .news-details-footer-container {
    .news-details-footer-content {
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;
      ${responsive.TABLET`
                width: 100%;
            `}
      /* border-bottom: 3px solid #BCA87A;
      border-right: 3px solid #bca87a; */
      animation: fadeInAnimation ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .discuss-button {
      margin-bottom: 35px;
      margin-top: 35px;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      font-family: ${FONTS.SECONDARY};
      background-color: ${COLORS.SECONDARY};
      /* width: 300px; */
      text-transform: uppercase;
      letter-spacing: 4px;
      padding-right: 24px;
      padding-left: 24px;
      &:hover {
        background-image: linear-gradient(to bottom, #e32733 10%, #69191e);
      }

      ${responsive.TABLET`
                font-size: 16px;
            `}
      ${responsive.PHABLET`
                margin : 20px 0;
                font-size: 14px;
            `}
    }

    .news-details-footer-links {
      /* width: 80%; */
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      letter-spacing: 4px;
      padding-right: 8px;
      padding-top: 24px;
      padding-bottom: 48px;
      img {
        /* padding-left: 8px; */
        /* padding-right: 8px; */
      }
    }
  }

  .related-news-container {
    flex-direction: column;

    .related-news-content-container {
      width: 100%;
      ${responsive.TABLET`
                    width: 100%;
            `}

      .related-news-cards {
        flex-direction: column;
      }
      .view-more-button {
        margin: 35px 0;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        font-family: ${FONTS.SECONDARY};
        background-color: ${COLORS.SECONDARY};
        width: 300px;
        height: 50px;
        text-transform: uppercase;
        letter-spacing: 4px;

        &:hover {
          background-image: linear-gradient(to bottom, #e32733 10%, #69191e);
        }

        ${responsive.TABLET`
                    width : 250px;
                    font-size: 16px;
                `}

        ${responsive.PHABLET`
                    margin : 20px 0;
                    font-size: 14px;
                `}
      }
    }
    .related-news-header {
      font-size: 24px;
      line-height: 26px;
      letter-spacing: 4px;
      text-transform: uppercase;
      position: relative;
    }
    .related-news-loading {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      ${responsive.PHABLET`
        display: none;
      `}
    }
    .carousel-container {
      /* width: 80%; */
    }

    .carousel-button {
      &:hover {
        background: none;
      }
    }

    .related-news-content {
      width: 85%;

    ${responsive.LAPTOP`
      align-items: flex-start;
    `}
    }
  }

  .view-more-container {
    position: absolute;
    bottom: 0pc;
    background: url(${ViewMoreBackground});
    background-position: center right;
    background-size: cover;
    height: 658px;
    width: 100%;
    background-repeat: no-repeat;
  }
`;

export const RelatedNewsWrapper = styled.div.attrs({
  className: 'RelatedNewsWrapper',
})`
  margin: 24px;
  width: 50%;
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  ${responsive.PHABLET`
        margin: 0;
        margin-top: 16px;
        margin-bottom: 16px;
        width: 100%;
    `}
  .related-news-image {
    margin-right: 24px;
    width: 50%;
    aspect-ratio: 16/9;
    margin-right: 16px;
    overflow: hidden;
    border-radius: 4px
    ${responsive.PHABLET`
            width: 100%;
            margin-bottom: 16px;
            margin-right: 0;
        `}
    img {
      transition: all 0.2s;
    }
  }

  .related-news-text-container {
    flex-direction: column;
    width: 50%;
    word-break: break-word;

    .related-news-title {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .related-news-text {
      font-size: 14px;
      line-height: 20px;
    }
  }

  ${responsive.LAPTOP`
        flex-direction: column

        .related-news-image {
            width: 100%;
        }

        .related-news-text-container {
            width: 100%;
            margin-top: 8px;
        }   
    `}
`;
