import styled from "styled-components";
import { COLORS, FONTS, responsive } from "../../Styles/Constants";
// import NewsBG from "Assets/images/News/NewsBG.svg";
import NewsHeaderBackgroundImage from "Assets/images/News/NewsHeaderBackgroundImage.png";
import NewsBackgroundImage from "Assets/images/News/NewsBackgroundImage.jpg";
import NewsHeader1 from "Assets/images/News/news_header1.png";
import HeaderIcon from "Assets/images/News/headerIcons.svg";
import BodyIcon from "Assets/images/News/bodyIcons.svg";
import BodyIconHover from "Assets/images/News/bodyIcons_hover.svg";
// import BodyBackground from "Assets/images/News/BG_Texture_01.png";
import ViewMoreBackground from "Assets/images/News/ViewMoreBG.png";
import BorderLeft from "Assets/images/Home/BorderLeft.png";
import BorderRight from "Assets/images/Home/BorderRight.png";

export const NewsContainer = styled.div.attrs({
  className: 'NewsContainer',
})`
  .bg-image {
    background: url(${NewsHeaderBackgroundImage});
    /* #4A4B4D, #141414 */
    background-position: center;
    background-size: cover;
    /* height: 100%; */
    height: 660px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 2000px) {
      height: 700px;
    }

    ${responsive.TABLET`
      height: 915px;
    `}

    ${responsive.MOBILE`
      height: 800px;
      justify-content: center;
    `}

    .carousel-container {
      width: 1200px;
      margin: 100px auto;

      @media (max-width: 1280px) {
        max-width: 1200px;
        width: 100%;
      }

      ${responsive.TABLET`
        max-width: 600px;
        height: 840px;
        margin: 40px auto;
      `}

      ${responsive.MOBILE`
        height: 650px;
      `}

      .CarouselItem {
        height: 360px;
        box-sizing: border-box;
        padding: 0 24px;

        ${responsive.TABLET`
          height: auto;
        `}

        & > div {
          height: 100%;
        }
      }

      .top-container {
        height: 100%;
      }

      .MuiIconButton-sizeSmall {
        @media (hover: hover) {
          &:hover {
            background-color: ${COLORS.SECONDARY} !important;
          }
        }
      }
    }

    .top-container {
      .image {
        width: 50%;
        position: relative;
      }

      .image-container {
        width: 100%;
        height: 100%;
      }

      .text {
        width: 80%;
      }

      .top {
        background: url(${BorderLeft});
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        height: 200px;
        right: 0;
        left: 7px;
        top: 7px;

        ${responsive.MOBILE`
          height: 166px;
          top: 9px;
          left: 7px;
        `}
      }

      .right {
        background: url(${BorderRight});
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 9px;
        top: 7px;
        height: 200px;
        width: 275px;

        ${responsive.MOBILE`
          top: 9px;
          right: 10px;
          width: 205px;
        `}
      }

      .bottom-left {
        background: url(${BorderRight});
        background-repeat: no-repeat;
        transform: rotate(180deg);
        background-size: contain;
        position: absolute;
        top: 169px;
        left: -97px;
        height: 175px;
        width: 379px;

        ${responsive.LAPTOP`
          top: 101px;
        `}

        ${responsive.TABLET`
          top: 169px;
        `}

        ${responsive.PHABLET`
          top: 68px;
        `}

        ${responsive.MOBILE`
          top: 9px;
          left: -105px;
          height: 170px;
        `}
      }

      .bottom-right {
        background: url(${BorderLeft});
        background-repeat: no-repeat;
        transform: rotate(180deg);
        background-size: contain;
        position: absolute;
        right: 9px;
        top: 144px;
        height: 200px;
        width: 344px;

        ${responsive.LAPTOP`
          top:  76px;
        `}

        ${responsive.TABLET`
          top: 144px;
        `}

        ${responsive.PHABLET`
          top: 43px;
        `}

        ${responsive.MOBILE`
          top: -21px;
          right: 10px;
          width: 210px;
        `}
      }

      ${responsive.TABLET`
        flex-direction: column;
        .image-container {
            width: 100%;
        }
        .text {
            width: 100%;
        }
      `}

      ${responsive.MOBILE`
        margin-top: 0;
      `}
    }

    .image {
      transition: 1 50s ease-in;
      /* background: url(${NewsHeader1}); */
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      max-width: 688px;
      max-height: 400px;
      padding: 16px;
      margin-right: 16px;
    }

    .text {
      font-size: 20px;
      font-family: ${FONTS.PRIMARY};
      color: #fff;
      margin: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .text-header {
        text-transform: uppercase;
        font-size: 32px;
        font-weight: bold;
        line-height: 34px;
        letter-spacing: 4px;
        padding-top: 16px;
        padding-bottom: 16px;
        font-weight: normal;

        ${responsive.TABLET`
            font-size: 24px;
        `}

        ${responsive.MOBILE`
          font-size: 14px;
        `}
      }

      .news-summary {
        font-weight: normal;
      }

      ${responsive.TABLET`
        border-top-right-radius: 0;
      `}
    }

    .read-more {
      margin: 35px 0;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      font-family: ${FONTS.GALDEANO};
      background-color: ${COLORS.SECONDARY};
      width: 300px;
      height: 50px;
      text-transform: uppercase;
      letter-spacing: 4px;
      &:hover {
        background-image: linear-gradient(to bottom, #e32733 10%, #69191e);
      }

      ${responsive.TABLET`
                width : 250px;
                font-size: 16px;
                margin: 35px 0;
            `}

      ${responsive.PHABLET`
                width : 150px;
                margin : 20px 0;
                font-size: 14px;
            `}
    }
  }
`;

export const HeaderIcons = styled.div.attrs({
  className: 'HeaderIcons',
})`
  .icon {
    background-image: url(${HeaderIcon});
    width: 40px;
    height: 40px;
    background-size: 160px;
    background-repeat: no-repeat;
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
    cursor: pointer;
    &:active {
      /* background-color: #b0b0b0; */
      /* box-shadow: 0 1px #666; */
      transform: translateY(4px);
    }
    &.window-icon {
      background-position: -4% 75%;
    }
    &.xbox-icon {
      background-position: 29% 75%;
    }
    &.ps-icon {
      background-position: 64% 75%;
    }
    &.epic-icon {
      background-position: 100% 75%;
    }
    &.no-hover {
      pointer-events: none;
    }
  }
`;

export const NewsBodyWrapper = styled.div.attrs({
  className: 'NewsBodyWrapper',
})`
  background: url(${NewsBackgroundImage});
  background-position: top center;
  background-size: 100%;
  background-repeat: repeat-y;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -80px;

  ${responsive.TABLET`
    background-position: center bottom;
    background-size: cover;
    margin-top: -116px;
  `}

  ${responsive.MOBILE`
    margin-top: -88px;
  `}

  .news-body-container {
    width: 100%;
    padding: 24px;
    z-index: 1;

    ${responsive.TABLET`
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0;
      margin-top: 24px;
    `}
    .news-body-content {
      width: 70%;

      ${responsive.TABLET`     
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0
      `}
      .body-header-container {
        ${responsive.PHABLET`
            padding-right: 16px;
            padding-left: 16px;
            // flex-wrap: wrap;
            justify-content: center;
        `}

        ${responsive.MOBILE`
          font-size: 16px;
        `}

        .filter-container {
          padding-left: 32px;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          text-transform: uppercase;
          letter-spacing: 4px;
          color: #b0b0b0;

          ${responsive.MOBILE`
            padding-left: 20px;
          `}
        }
      }

      .body-sub-header-container {
        .body-sub-header {
          cursor: pointer;
          margin-top: 24px;
          margin-bottom: 24px;
          padding-left: 24px;
          padding-right: 24px;
          text-transform: uppercase;
          letter-spacing: 2px;
          border-right: 2px solid #bca87a;

          &:first-child {
            padding-left: 0;

            ${responsive.TABLET`
                            padding-left: 24px;

                        `}
          }
          ${responsive.TABLET`
                        font-size: 12px;
                    `}

          span:hover {
            color: #2d448a;
            border-bottom: 1px solid #2d448a;
          }

          &:nth-child() {
          }

          &:last-child {
            padding-right: 0;
            border-right: none;
          }
        }

        .body-sub-header--selected span {
          border-bottom: 1px solid #2d448a;
        }

        .divider {
          width: 2px;
          border-left: 1px solid #bca87a;
          border-right: 1px solid #bca87a;
          margin-top: 24px;
          margin-bottom: 24px;
        }
      }

      .body-sub-header-select {
        margin: 10px;
        max-width: 280px;
        text-transform: uppercase;
      }

      .news-list-container {
        /* dark theme design */

        ${responsive.TABLET`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
      `}

      .news-status-text {
        font-size: 20px;
      }
      }
    }

    .news-body-social-container {
      width: 30%;
      padding-left: 24px;
      ${responsive.TABLET`
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding-left: 0;
                padding-right: 0;

            `}
      .social-list-container {
        ${responsive.TABLET`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                `}
      }
    }

    .header {
      font-size: 24px;
      font-weight: 400;
      line-height: 26px;
      text-transform: uppercase;

      letter-spacing: 4px;
    }
  }

  .view-more-button {
    margin: 35px 0;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: ${FONTS.SECONDARY};
    background-color: ${COLORS.SECONDARY};
    width: 300px;
    height: 50px;
    text-transform: uppercase;
    letter-spacing: 4px;

    &:hover {
      background-image: linear-gradient(to bottom, #e32733 10%, #69191e);
    }

    ${responsive.TABLET`
            width : 250px;
            font-size: 16px;
          `}

    ${responsive.PHABLET`
            width : 150px;
            margin : 20px 0;
            font-size: 14px;
          `}
  }

  .news-body-padding-bottom {
    padding-bottom: 106px;
  }

  .view-more-text-container {
    position: absolute;
    bottom: 0;
    flex-direction: column;
    margin-bottom: 100px;
    z-index: 1;
    cursor: pointer;
    &:hover {
      .view-more-text {
        color: #B6212B;
      }
      svg {
        path {
          fill: #B6212B;
        }
      }
    }
    &:active {
      .view-more-icon {
        transform: translateY(4px);
      }
    }
    & .view-more-icon, svg path {
      transition: all 0.2s;
    }
    .view-more-text {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 4px;
      text-transform: uppercase;
      line-height: 24px;
      color: #c24042;
      cursor: pointer;
      transition: all 0.2s;
    }
  }
  .view-more-container {
    position: absolute;
    bottom: 0pc;
    background: url(${ViewMoreBackground});
    background-position: center right;
    background-size: cover;
    height: 658px;
    width: 100%;
    background-repeat: no-repeat;
  }
`;

export const ViewMoreContainer = styled.div.attrs({
  className: 'ViewMoreContainer',
})`
  width: 100%;
`;

export const NewsBodyFilterIcons = styled.span.attrs({
  className: 'NewsBodyFilterIcons',
})`
  .icon {
    background-image: url(${BodyIcon});
    width: 40px;
    height: 40px;
    background-size: 138px;
    background-repeat: no-repeat;
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &.window-icon {
      background-position: -4% 50%;
    }
    &.xbox-icon {
      background-position: 30% 50%;
    }
    &.ps-icon {
      background-position: 67% 50%;
    }
    &.epic-icon {
      background-position: 105% 50%;
    }
    &:hover {
      background-image: url(${BodyIconHover});
    }
    &--selected {
      background-image: url(${BodyIconHover});
    }

    ${responsive.MOBILE`
      width: 30px;
      height: 30px;
    `}
  }
`;

export const NewsBodyIcons = styled.span.attrs({
  className: 'NewsBodyIcons',
})`
  .icon {
    background-image: url(${BodyIcon});
    width: 40px;
    height: 40px;
    background-size: 138px;
    background-repeat: no-repeat;
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
    overflow: hidden;
    position: relative;

    &.window-icon {
      background-position: -4% 50%;
    }
    &.xbox-icon {
      background-position: 30% 50%;
    }
    &.ps-icon {
      background-position: 67% 50%;
    }
    &.epic-icon {
      background-position: 105% 50%;
    }
  }
`;

export const NewsContentWrapper = styled.div.attrs({
  className: 'NewsContentWrapper',
})`
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: pointer;
  &:hover {
    .news-image {
      transform: scale(1.05);
    }
  }
  ${responsive.TABLET`
        flex-direction: column
    `}

  .news-content-image {
    width: 100%;
    display: flex;
    aspect-ratio: 16 / 9;
    justify-content: center;
    overflow: hidden;
    margin-right: 16px;
    border-radius: 4px;
    ${responsive.TABLET`
      height: auto;
    `}
  };

    ${responsive.LAPTOP`
      min-width: 340px;
    `}
    
    ${responsive.TABLET`
        width: 100%;
        min-width: auto;
        max-width: 380px;
        height: auto;
        margin-right: 0;
        justify-content: center;
    `}

    .news-image {
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      background-size: cover;
      transition: all 0.2s;

      ${responsive.PHABLET`
        background-position: center;
      `}
    }
  }
  .news-content-text {
    width: 100%;
    flex-direction: column;
    word-break: break-word;

    ${responsive.PHABLET`
            width: 100%;
        `}

    .news-content-header {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: #bca87a;
      line-height: 24px;
      letter-spacing: 2px;

      ${responsive.TABLET`
        margin: 12px 0;
      `}
    }

    .news-content-main-text {
      font-size: 18px;
      text-transform: uppercase;
      line-height: 24px;
      letter-spacing: 2px;
      color: ${COLORS.GOLDEN_TITLE_COLOR};

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-block-end: 0.5em;
    }

    .news-content-main-date {
      font-size: 12px;
      color: #000;
      opacity: 0.7;
      margin: 0;
    }

    .news-content-sub-text {
      margin-block-start: 0.5em;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    ${responsive.MOBILE`
      border-top-right-radius: 0;
    `}
  }
`;

export const SocialContentWrapper = styled.div.attrs({
  className: 'SocialContentWrapper',
})`
  padding-top: 24px;
  padding-bottom: 24px;
  .social-content-card-conatiner {
    word-break: break-all;
    max-width: 345px;
    border: 1px solid #bca87a;
    box-shadow: none;
    border-radius: 0;
    .social-content-title {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 2px;
      font-family: ${FONTS.PRIMARY};
    }
    .social-content-subheader {
      font-size: 12px;
      font-family: ${FONTS.PRIMARY};
    }

    .social-content-text {
      font-size: 14px;
      line-height: 20px;
      font-family: ${FONTS.PRIMARY};
    }
  }

  .social-content-image {
    height: 0;
    padding-top: 56.25%;
  }
`;
